<template>
  <b-nav-item-dropdown
    text="Resources"
    class="px-4 d-none d-lg-inline resource-nav-desktop"
    menu-class="resource-nav-desktop__dropdown-menu"
  >
    <b-dropdown-item
      class="dropdown-list-item"
      :to="{ name: routes.PROVIDER_RESOURCES_PAGE }"
    >
      Provider
    </b-dropdown-item>
    <b-dropdown-item
      class="dropdown-list-item"
      :to="{ name: routes.EMPLOYER_RESOURCES_PAGE }"
    >
      Employer
    </b-dropdown-item>
    <b-dropdown-item
      class="dropdown-list-item"
      :to="{ name: routes.BROKER_RESOURCES_PAGE }"
    >
      Broker
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { PROVIDER_RESOURCES_PAGE, EMPLOYER_RESOURCES_PAGE, BROKER_RESOURCES_PAGE } from '../../../router/routes.types'
export default {
  name: 'ResourcesNavDesktop',
  data () {
    return {
      routes: {
        PROVIDER_RESOURCES_PAGE,
        EMPLOYER_RESOURCES_PAGE,
        BROKER_RESOURCES_PAGE
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.resource-nav-desktop {
  &::v-deep .dropdown-list-item {
    border-bottom: 1px solid $brand-light-gray-1;

    &:last-child {
      border-bottom: none;
    }
  }

  &::v-deep .nav-link {
    outline: none;
  }

  &::v-deep .dropdown-item {
    color: $black;
    font-size: 18px;
    font-weight: normal;
    padding: 0.5rem 0.75rem;
    text-decoration: none;

    &:hover {
      color: $brand-purple-2;
    }
  }

  &::v-deep &__dropdown-menu {
    outline: none;
    padding: 0;
    filter: drop-shadow(0 4px 0.25rem $brand-medium-gray-1);
  }

  @include media-breakpoint-up(lg) {
    &::v-deep .dropdown-item {
      font-size: 14px;
    }
  }
}
</style>
