import { find } from 'lodash'

export default {
  computed: {
    selectedLocalization () {
      const selectedLocalization = find(this.item.localization.localizations, ['localization.culture', this.$oc.selectedCulture])

      if (selectedLocalization !== undefined && selectedLocalization != null) {
        return selectedLocalization
      }

      const defaultLocalization = find(this.item.localization.localizations, ['localization.culture', this.$oc.defaultCulture])

      if (defaultLocalization !== null) {
        return defaultLocalization
      } else {
        throw new Error(`No valid localization found for ${this.item.alias}`)
      }
    }
  }
}
