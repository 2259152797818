<template>
  <div
    v-if="show"
    class="orchardcore-text-unformatted"
    :data-orchardcore-contentitem="item.Id"
    v-html="text"
  />
</template>

<script>
import { sprintf } from 'sprintf-js'
import orchardCoreComponentMixin from '../mixins/OrchardCoreComponent'
import nl2br from 'nl2br'

export default {
  name: 'OrchardcoreTextUnformatted',
  mixins: [orchardCoreComponentMixin],
  props: {
    item: { type: Object, default: null },
    data: { type: Object, default: null }
  },
  computed: {
    show () {
      return this.item !== undefined && this.item !== null
    },
    text () {
      let result = this.selectedLocalization.content

      if (this.data !== null) {
        try {
          result = sprintf(result, this.data)
        } catch (e) {
        }
      }

      return nl2br(result, false)
    }
  }
}
</script>

<style>
.orchardcore-text-unformatted {
  display: inline;
}
</style>
