import locationListingSettings from '../../lib/locationListingSettings'

import { find, orderBy } from 'lodash'

// prefix all getters, mutations and actions with PROCEDURE_DETAIL__ to "namespace" them

export const mutationTypes = {
  PROCEDURE_DETAIL__SET_FACILITY_ORDER_NUMBER_START: 'procedureDetail_SetFacilityOrderNumberStart',
  PROCEDURE_DETAIL__SET_LOCATION_LISTING_DISTANCE_FILTER: 'procedureDetail_SetLocationListingDistanceFilter',
  PROCEDURE_DETAIL__SET_LOCATION_LISTING_MOBILE_DISPLAY_MODE: 'procedureDetail_SetLocationListingMobileDisplayMode',
  PROCEDURE_DETAIL__SET_LOCATION_LISTING_MOBILE_SHOW_SORTING_FILTERING_CONTROLS: 'procedureDetail_SetLocationListingMobileShowSortingFilteringControls',
  PROCEDURE_DETAIL__SET_LOCATION_LISTING_SORT: 'procedureDetail_SetLocationListingsort',
  PROCEDURE_DETAIL__SET_LOCATION_LISTING_FACILITY_TYPE_FILTER: 'procedureDetail_SetLocationListingFacilityTypeFilter',
  PROCEDURE_DETAIL__SET_LOCATION_LISTING_PROVIDER_TYPE_FILTER: 'procedureDetail_SetLocationListingProviderTypeFilter',
  PROCEDURE_DETAIL__SET_PAGE_DATA: 'procedureDetail_SetPageData',
  PROCEDURE_DETAIL__SET_SELECTED_MAP_LOCATION: 'procedureDetail_SetSelectedMapLocation',
  PROCEDURE_DETAIL__SET_VISIBLE_FACILITY_LISTINGS: 'procedureDetail_SetVisibleFacilityListings',
  PROCEDURE_DETAIL__TOGGLE_MAP_FULL_SIZE: 'procedureDetail_ToggleMapFullSize'
}

export const getterTypes = {
  PROCEDURE_DETAIL__GET_MOBILE_LOCATION_LISTING_MODE: 'procedureDetail_GetMobileLocationListingMode',
  PROCEDURE_DETAIL__GET_SHOW_ALL_PROVIDERS: 'procedureDetail_GetShowAllProviders',
  PROCEDURE_DETAIL__GET_SHOW_PROVIDER_TYPE_FILTERS: 'procedureDetail_GetShowProviderTypeFilters',
  PROCEDURE_DETAIL__GET_SORTED_PROCEDURE_BUNDLES: 'procedureDetail_GetSortedProcedureBundles',
  PROCEDURE_DETAIL__GET_SORTED_NEARBY_FACILITIES: 'procedureDetail_GetSortedNearbyFacilities',
  PROCEDURE_DETAIL__GET_MAP_LOCATIONS: 'procedureDetail_getMapLocations'
}

export const actionTypes = {
  PROCEDURE_DETAIL__SET_SELECTED_MAP_LOCATION_BY_ORDER_NUMBER: 'procedureDetail_SetSelectedMapLocationByOrderNumber'
}

export const mobileLocationListingDisplayModes = {
  list: 'List',
  map: 'Map'
}

const minDisplayItemsForDistanceFilter = 2

export default {
  state: {
    facilityOrderNumberStart: 0,
    facilityTypeFilterOptions: [],
    mapFullSize: false,
    pageData: null,
    selectedMapLocation: null,
    visibleFacilityListings: null,
    locationListingSettings: {
      sort: locationListingSettings.sorters[0],
      distanceFilter: null,
      facilityTypeFilter: null,
      providerTypeFilter: locationListingSettings.providerTypeFilters[0],
      mobileDisplayMode: mobileLocationListingDisplayModes.list,
      showMobileFiltersSortingControls: false
    }
  },
  getters: {
    [getterTypes.PROCEDURE_DETAIL__GET_SHOW_ALL_PROVIDERS]: state => {
      return state.locationListingSettings.providerTypeFilter.key === locationListingSettings.PROVIDER_FILTER_KEYS.ALL_PROVIDERS
    },
    [getterTypes.PROCEDURE_DETAIL__GET_SORTED_NEARBY_FACILITIES]: (state) => {
      const sorted = orderBy(state.pageData.nearbyFacilities, state.locationListingSettings.sort.sortFunction, state.locationListingSettings.sort.direction)

      const distanceFiltered = sorted.filter(location => {
        if (location.physician !== null && location.physician.distanceFromSelectedLocation !== null) {
          return location.physician.distanceFromSelectedLocation <= state.locationListingSettings.distanceFilter.maxDistanceMiles
        } else if (location.facility !== null && location.facility.distanceFromSelectedLocation !== null) {
          return location.facility.distanceFromSelectedLocation <= state.locationListingSettings.distanceFilter.maxDistanceMiles
        }

        return false
      })

      const facilityTypeFiltered = distanceFiltered.filter(location => {
        if (state.locationListingSettings.facilityTypeFilter.facilityTypeId === null) {
          return true
        }

        return location.facility.facilityType === state.locationListingSettings.facilityTypeFilter.facilityTypeId
      })

      return facilityTypeFiltered
    },
    [getterTypes.PROCEDURE_DETAIL__GET_MAP_LOCATIONS]: (state, getters) => {
      let result = getters[getterTypes.PROCEDURE_DETAIL__GET_SORTED_PROCEDURE_BUNDLES].map((ll, index) => {
        return {
          position: getLocationListingPosition(ll),
          facility: ll.facility,
          procedureBundle: ll.procedureBundle,
          name: (ll.physician !== null) ? ll.physician.name : ll.facility.name,
          price: ll.procedureBundle.feeSingle,
          priceLevel: getProcedureBundlePriceLevel(state.pageData.procedure, ll.procedureBundle),
          orderNumber: index + 1
        }
      })

      const startFacilityCountNum = state.facilityOrderNumberStart

      if (state.visibleFacilityListings !== null && state.locationListingSettings.providerTypeFilter.key === locationListingSettings.PROVIDER_FILTER_KEYS.ALL_PROVIDERS) {
        const facilityLocations = state.visibleFacilityListings.map((ll, index) => {
          return {
            position: getLocationListingPosition(ll),
            facility: ll.facility,
            procedureBundle: null,
            name: ll.facility.name,
            price: null,
            priceLevel: ll.facility.priceLevel,
            orderNumber: startFacilityCountNum + index
          }
        })

        result = result.concat(facilityLocations)
      }

      return result.filter(ml => {
        return ml.position !== null
      })
    },
    [getterTypes.PROCEDURE_DETAIL__GET_SORTED_PROCEDURE_BUNDLES]: state => {
      const sorted = orderBy(state.pageData.procedureBundles, state.locationListingSettings.sort.sortFunction, state.locationListingSettings.sort.direction)

      const distanceFiltered = sorted.filter(location => {
        if (location.physician !== null && location.physician.distanceFromSelectedLocation !== null) {
          return location.physician.distanceFromSelectedLocation <= state.locationListingSettings.distanceFilter.maxDistanceMiles
        } else if (location.facility !== null && location.facility.distanceFromSelectedLocation !== null) {
          return location.facility.distanceFromSelectedLocation <= state.locationListingSettings.distanceFilter.maxDistanceMiles
        }

        return false
      })

      const facilityTypeFiltered = distanceFiltered.filter(location => {
        if (state.locationListingSettings.facilityTypeFilter.facilityTypeId === null) {
          return true
        }

        return location.facility.facilityType === state.locationListingSettings.facilityTypeFilter.facilityTypeId
      })

      return facilityTypeFiltered
    },
    [getterTypes.PROCEDURE_DETAIL__GET_SHOW_PROVIDER_TYPE_FILTERS]: (state) => {
      const procedureBundles = state.pageData.procedureBundles
      const nearbyFacilities = state.pageData.nearbyFacilities

      return procedureBundles.length > 0 && nearbyFacilities.length > 0
    },
    [getterTypes.PROCEDURE_DETAIL__GET_MOBILE_LOCATION_LISTING_MODE]: state => {
      return state.locationListingSettings.mobileDisplayMode
    }
  },
  mutations: {
    [mutationTypes.PROCEDURE_DETAIL__SET_PAGE_DATA]: (state, value) => {
      state.pageData = value

      state.pageData.procedureBundles = state.pageData.procedureBundles.map(ll => {
        ll.priceLevel = getProcedureBundlePriceLevel(state.pageData.procedure, ll.procedureBundle)

        return ll
      })

      if (state.pageData.procedureBundles.length === 0) {
        state.locationListingSettings.providerTypeFilter = find(locationListingSettings.providerTypeFilters, { key: locationListingSettings.PROVIDER_FILTER_KEYS.ALL_PROVIDERS })
      }

      // find the filtering distance where at least minDisplayItemsForDistanceFilter items are within the distance and set that filter as the default
      locationListingSettings.distanceFilters.every((distanceFilter) => {
        const itemsWithinDistance = state.pageData.procedureBundles.filter((location) => {
          if (location.physician !== null && location.physician.distanceFromSelectedLocation !== null) {
            return location.physician.distanceFromSelectedLocation <= distanceFilter.maxDistanceMiles
          } else if (location.facility !== null && location.facility.distanceFromSelectedLocation !== null) {
            return location.facility.distanceFromSelectedLocation <= distanceFilter.maxDistanceMiles
          }

          return false
        })

        if (itemsWithinDistance.length >= minDisplayItemsForDistanceFilter) {
          state.locationListingSettings.distanceFilter = distanceFilter
          return false
        }

        return true
      })

      if (state.locationListingSettings.distanceFilter === null) {
        state.locationListingSettings.distanceFilter = locationListingSettings.distanceFilters[locationListingSettings.distanceFilters.length - 1]
      }

      // TODO: make this a computed property via getter if possible. Got errors when trying to do it too fast for demo deadline.
      state.facilityTypeFilterOptions = []
      state.facilityTypeFilterOptions.push({ facilityTypeId: null, label: 'All' })
      Object.keys(state.pageData.facilityTypes).forEach((key) => {
        state.facilityTypeFilterOptions.push({ facilityTypeId: parseInt(key, 10), label: state.pageData.facilityTypes[key] })
      })
      state.locationListingSettings.facilityTypeFilter = state.facilityTypeFilterOptions[0]
    },
    [mutationTypes.PROCEDURE_DETAIL__SET_VISIBLE_FACILITY_LISTINGS]: (state, value) => {
      state.visibleFacilityListings = value
    },
    [mutationTypes.PROCEDURE_DETAIL__TOGGLE_MAP_FULL_SIZE]: (state) => {
      state.mapFullSize = !state.mapFullSize
    },
    [mutationTypes.PROCEDURE_DETAIL__SET_FACILITY_ORDER_NUMBER_START]: (state, value) => {
      state.facilityOrderNumberStart = value
    },
    [mutationTypes.PROCEDURE_DETAIL__SET_SELECTED_MAP_LOCATION]: (state, value) => {
      state.locationListingSettings.mobileDisplayMode = mobileLocationListingDisplayModes.map
      state.selectedMapLocation = value
    },
    [mutationTypes.PROCEDURE_DETAIL__SET_LOCATION_LISTING_SORT]: (state, value) => {
      state.locationListingSettings.sort = value
    },
    [mutationTypes.PROCEDURE_DETAIL__SET_LOCATION_LISTING_DISTANCE_FILTER]: (state, value) => {
      state.locationListingSettings.distanceFilter = value
    },
    [mutationTypes.PROCEDURE_DETAIL__SET_LOCATION_LISTING_FACILITY_TYPE_FILTER]: (state, value) => {
      state.locationListingSettings.facilityTypeFilter = value
    },
    [mutationTypes.PROCEDURE_DETAIL__SET_LOCATION_LISTING_PROVIDER_TYPE_FILTER]: (state, value) => {
      state.locationListingSettings.providerTypeFilter = value
    },
    [mutationTypes.PROCEDURE_DETAIL__SET_LOCATION_LISTING_MOBILE_DISPLAY_MODE]: (state, value) => {
      state.locationListingSettings.mobileDisplayMode = value
    },
    [mutationTypes.PROCEDURE_DETAIL__SET_LOCATION_LISTING_MOBILE_SHOW_SORTING_FILTERING_CONTROLS]: (state, value) => {
      state.locationListingSettings.showMobileFiltersSortingControls = value
    }
  },
  actions: {
    [actionTypes.PROCEDURE_DETAIL__SET_SELECTED_MAP_LOCATION_BY_ORDER_NUMBER]: ({ commit, getters }, orderNumber) => {
      const selectedLocation = find(getters[getterTypes.PROCEDURE_DETAIL__GET_MAP_LOCATIONS], { orderNumber })

      if (selectedLocation !== undefined) {
        commit(mutationTypes.PROCEDURE_DETAIL__SET_SELECTED_MAP_LOCATION, selectedLocation)
      } else {
        throw new Error(`Could not find location that matches order number ${orderNumber}`)
      }
    }
  }
}

const getLocationListingPosition = (locationListing) => {
  let locationPosition = null

  if (locationListing.facility && locationListing.facility.latitude !== null && locationListing.facility.longitude !== null) {
    locationPosition = { lat: locationListing.facility.latitude, lng: locationListing.facility.longitude }
  }

  return locationPosition
}

const getProcedureBundlePriceLevel = (procedure, procedureBundle) => {
  const fairPrice = procedure.procedurePrices[0].fairPrice
  const bundlePrice = procedureBundle.feeSingle

  const fairPriceLowerBound = fairPrice * 0.8
  const fairPriceUpperBound = fairPrice * 1.2

  if (bundlePrice < fairPriceLowerBound) {
    return 5 // great price
  } else if (bundlePrice > fairPriceUpperBound) {
    return 50 // high price
  } else {
    return 25 // fair price
  }
}
