<template>
  <b-alert
    :key="appAlertId"
    :show="dismissSecs"
    :variant="appAlertVariant"
    class="position-fixed fixed-top m-0 rounded-0 app-alert"
    dismissible
    @dismissed="dismissCountDown=0"
    @dismiss-count-down="countDownChanged"
  >
    {{ appAlertMsg }}
  </b-alert>
</template>

<script>
import { mutationTypes } from '../../store/modules/app'

export default {
  name: 'AppAlert',
  data () {
    return {
      dismissSecs: 0,
      dismissCountDown: 0,
      dismissAfterSeconds: 5
    }
  },
  computed: {
    appAlertId () {
      return this.$store.state.app.alertId
    },
    appAlertMsg () {
      return this.$store.state.app.alertMsg
    },
    appAlertVariant () {
      return this.$store.state.app.alertVariant
    }
  },
  watch: {
    appAlertMsg (v, ov) {
      if (v !== null) {
        this.dismissSecs = this.dismissAfterSeconds
      } else {
        this.dismissSecs = 0
      }
    },
    dismissCountDown (v, ov) {
      if (v === 0) {
        this.$store.commit(mutationTypes.APP__CLEAR_ALERT)
      }
    }
  },
  methods: {
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert () {
      this.dismissCountDown = this.dismissAfterSeconds
    }
  }
}
</script>

<style lang="scss" scoped>
.app-alert {
  z-index: 2000;
}
</style>
