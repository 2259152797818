<template>
  <div class="testimonials mb-4">
    <div class="testimonials__foreground">
      <div class="container-fluid container-lg">
        <div class="row">
          <div class="col-8 offset-2 col-md-12 offset-md-0">
            <h2 class="testimonials__title mb-3 mb-lg-4">
              <oc-content :item="$oc.content.homePageTestimonialsTitle" />
              <dots-ornament />
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <div class="testimonials__lede mb-3 mb-md-4 text-center">
              <oc-content :item="$oc.content.homePageTestimonialsLede" />
            </div>
          </div>
        </div>
        <mobile-carousel
          v-if="showMobileCarousel"
          :testimonials="testimonialsSorted"
        />
        <div
          v-if="$resize && isMdUp && !showDesktopCarousel"
          class="row"
        >
          <div
            v-for="(testimonial, tIndex) in testimonialsSorted"
            :key="tIndex"
            class="col-12 col-md-4"
          >
            <testimonial-item :testimonial="testimonial" />
          </div>
        </div>
        <desktop-carousel
          v-if="$resize && isMdUp && showDesktopCarousel"
          :testimonials="testimonialsSorted"
          :page-size="desktopCarouselPageSize"
        />
      </div>
    </div>
    <div class="testimonials__background" />
  </div>
</template>

<script>
import DesktopCarousel from './DesktopCarousel/DesktopCarousel'
import DotsOrnament from '../../Shared/DotsOrnament'
import MobileCarousel from './MobileCarousel'
import TestimonialItem from './TestimonialItem'

import { CommonBands } from 'vue-media-queries'
import { orderBy } from 'lodash'

export default {
  name: 'TestimonialsSection',
  components: {
    DesktopCarousel,
    DotsOrnament,
    MobileCarousel,
    TestimonialItem
  },
  mixins: [CommonBands.Bootstrap4.mixin],
  props: {
    testimonials: { type: Array, default: function () { return [] }, required: true }
  },
  data () {
    return {
      desktopCarouselPageSize: 3
    }
  },
  computed: {
    showMobileCarousel () {
      return this.$resize && this.isSmDown
    },
    showDesktopCarousel () {
      return this.testimonialsSorted.length > this.desktopCarouselPageSize
    },
    testimonialsSorted () {
      return orderBy(this.testimonials, ['sortOrder', 'asc'])
    }
  }
}
</script>

<style lang="scss" scoped>
.testimonials {
  color: $white;
  padding: 2rem 0 0;
  position: relative;

  &__foreground {
    margin-top: 3rem;
    padding-bottom: 2rem;
    position: relative;
    z-index: 2;
  }

  &__background {
    background: $primary;
    clip-path: ellipse(200% 100% at 50% 0%);
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    transform: rotate(180deg);
    width: 100%;
    z-index: 1;
  }

  &__title {
    font-size: 1.6rem;
    font-weight: normal;
    margin-bottom: 0;
    text-align: center;
  }

  @include media-breakpoint-up(md) {
    &__foreground {
      margin-top: 2rem;
    }

    &__background {
      clip-path: ellipse(120% 100% at 50% 0%);
    }

    &__title {
      font-size: 2rem;
      margin-bottom: 2rem;
    }

    &__lede {
      font-size: 1.3rem;
      line-height: 2rem;
    }
  }

  @include media-breakpoint-up(lg) {
    &__background {
      clip-path: ellipse(80% 100% at 50% 0%);
    }

    &__title {
      margin-bottom: 1rem;
    }
  }
}
</style>
