<template>
  <b-carousel-slide img-blank>
    <testimonial-item :testimonial="testimonial" />
  </b-carousel-slide>
</template>

<script>
import TestimonialItem from './TestimonialItem'

export default {
  name: 'TestimonialCarouselSlide',
  components: { TestimonialItem },
  props: {
    testimonial: { type: Object, default: null, required: true }
  }
}
</script>
