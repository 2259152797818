export const ABOUT_PAGE = 'AboutPage'
export const BROKER_RESOURCES_PAGE = 'BrokerResourcesPage'
export const CONTACT_PAGE = 'ContactPage'
export const EMPLOYER_RESOURCES_PAGE = 'EmployerResourcesPage'
export const HOME_PAGE = 'HomePage'
export const PRIVACY_PAGE = 'PrivacyPage'
export const PROCEDURE_CATEGORY_PAGE = 'ProcedureCategoryPage'
export const PROCEDURE_DETAIL_PAGE = 'ProcedureDetailPage'
export const PROVIDER_RESOURCES_PAGE = 'ProviderResourcesPage'
export const TERMS_PAGE = 'TermsPage'

export default {
  ABOUT_PAGE,
  BROKER_RESOURCES_PAGE,
  CONTACT_PAGE,
  EMPLOYER_RESOURCES_PAGE,
  HOME_PAGE,
  PRIVACY_PAGE,
  PROCEDURE_CATEGORY_PAGE,
  PROCEDURE_DETAIL_PAGE,
  PROVIDER_RESOURCES_PAGE,
  TERMS_PAGE
}
