<template>
  <ul class="navbar-nav nav-menu ml-auto">
    <li class="nav-item px-4 d-lg-none">
      <router-link
        :to="{ name: routes.HOME_PAGE }"
        class="nav-link"
      >
        Main Page
      </router-link>
    </li>
    <li class="nav-item px-4">
      <router-link
        :to="{ name: routes.ABOUT_PAGE }"
        class="nav-link"
      >
        About Us
      </router-link>
    </li>
    <li class="nav-item px-4 d-sm-block d-lg-none">
      <span class="navbar-text">Resources</span>
      <resources-nav-mobile />
    </li>
    <resources-nav-desktop />
    <li class="nav-item px-4">
      <router-link
        :to="{ name: routes.CONTACT_PAGE }"
        class="nav-link"
      >
        Contact Us
      </router-link>
    </li>
    <li
      v-if="!signedInUserId"
      class="nav-item px-4"
    >
      <a
        class="nav-link"
        href="#"
        @click.prevent="handleSignInUserClick"
      >
        <font-awesome-icon icon="user" />
      </a>
    </li>
    <signed-in-user-nav
      v-if="signedInUserId"
      :username="signedInUserId"
    />
    <user-sign-in-modal ref="userSignInModal" />
  </ul>
</template>

<script>
import ResourcesNavDesktop from './ResourcesNavDesktop'
import ResourcesNavMobile from './ResourcesNavMobile'
import SignedInUserNav from './SignedInUserNav'
import UserSignInModal from '../../Shared/UserSignIn/UserSignInModal'

import {
  ABOUT_PAGE,
  HOME_PAGE,
  CONTACT_PAGE
} from '../../../router/routes.types'

export default {
  name: 'NavBar',
  components: {
    ResourcesNavDesktop,
    ResourcesNavMobile,
    UserSignInModal,
    SignedInUserNav
  },
  data () {
    return {
      routes: {
        ABOUT_PAGE,
        HOME_PAGE,
        CONTACT_PAGE
      }
    }
  },
  computed: {
    signedInUserId () {
      return this.$store.state.app.signedInUserId
    }
  },
  methods: {
    handleSignInUserClick () {
      this.$refs.userSignInModal.setShowModal(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-menu {
  border-top: 1px solid $brand-light-gray-1;

  & > .nav-item {
    border-bottom: 1px solid $brand-light-gray-1;
  }

  @include media-breakpoint-up(lg) {
    border-top-color: transparent;

    & > .nav-item {
      border-bottom-color: transparent;
    }
  }
}
</style>
