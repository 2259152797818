<template>
  <button
    class="navbar-toggler"
    type="button"
    aria-expanded="false"
    aria-label="Toggle navigation"
    @click="$emit('clicked')"
  >
    <font-awesome-icon
      v-if="!expandNavCollapse"
      icon="bars"
      class="navbar-toggler__icon"
    />
    <font-awesome-icon
      v-if="expandNavCollapse"
      icon="times"
      class="navbar-toggler__icon"
    />
  </button>
</template>

<script>
export default {
  name: 'NavBarToggler',
  props: {
    expandNavCollapse: { type: Boolean, default: false, required: true }
  }
}
</script>

<style lang="scss" scoped>
.navbar-toggler {
  border-color: transparent;
  padding: 0;

  &__icon {
    color: $primary;
  }

  @include media-breakpoint-up(md) {
    padding: 4px 12px;
  }
}
</style>
