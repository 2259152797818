<template>
  <b-img
    :src="url"
    :alt="altText"
    fluid
  />
</template>

<script>
import orchardCoreComponentMixin from '../mixins/OrchardCoreComponent'

export default {
  name: 'OrchardcoreImage',
  mixins: [orchardCoreComponentMixin],
  props: {
    item: { type: Object, default: null, required: true }
  },
  computed: {
    url () {
      return `${this.$oc.orchardCoreUrlBase}${this.selectedLocalization.image.urls[0]}`
    },
    altText () {
      return this.selectedLocalization.altText
    }
  }
}
</script>

<style>

</style>
