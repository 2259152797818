<template>
  <div class="home">
    <div
      v-if="pageDataLoaded === true"
      class="home__page-wrapper"
    >
      <div class="home__page-foreground">
        <jumbotron-section />
        <featured-by-section :featured-bys="featuredBys" />
        <why-section />
        <how-works-section />
        <faq-section :faqs="faqs" />
        <testimonials-section :testimonials="testimonials" />
        <cta-button />
      </div>
      <div class="home__page-background" />
    </div>
  </div>
</template>

<script>
import CtaButton from '../components/HomePage/CtaButton'
import FaqSection from '../components/HomePage/FaqSection/FaqSection'
import FeaturedBySection from '../components/HomePage/FeaturedBySection/FeaturedBySection'
import HowWorksSection from '../components/HomePage/HowWorksSection/HowWorksSection'
import JumbotronSection from '../components/HomePage/JumbotronSection'
import TestimonialsSection from '../components/HomePage/TestimonialsSection/TestimonialsSection'
import WhySection from '../components/HomePage/WhySection'

import {
  getOrchardCoreContentModule,
  getOrchardCoreFaqs,
  getOrchardCoreTestimonials,
  getOrcharcCoreFeaturedBys
} from '../lib/api'
import pageMixin from '../lib/mixins/page'

export default {
  name: 'HomePage',
  components: {
    CtaButton,
    FaqSection,
    FeaturedBySection,
    HowWorksSection,
    JumbotronSection,
    TestimonialsSection,
    WhySection
  },
  mixins: [pageMixin],
  data () {
    return {
      faqs: [],
      testimonials: [],
      featuredBys: []
    }
  },
  async mounted () {
    try {
      const jumbotronResponse = await getOrchardCoreContentModule('home-page-jumbotron')
      this.$oc.setContent(jumbotronResponse.data)

      const whyResponse = await getOrchardCoreContentModule('home-page-why')
      this.$oc.setContent(whyResponse.data)

      const howWorksResponse = await getOrchardCoreContentModule('home-page-how-works')
      this.$oc.setContent(howWorksResponse.data)

      const faqContentResponse = await getOrchardCoreContentModule('home-page-faq')
      this.$oc.setContent(faqContentResponse.data)

      const testimonialsContentResponse = await getOrchardCoreContentModule('home-page-testimonials')
      this.$oc.setContent(testimonialsContentResponse.data)

      const featuredByContentResponse = await getOrchardCoreContentModule('home-page-featured-by')
      this.$oc.setContent(featuredByContentResponse.data)

      const faqsResponse = await getOrchardCoreFaqs()
      this.faqs = faqsResponse.data

      const testimonialsResponse = await getOrchardCoreTestimonials()
      this.testimonials = testimonialsResponse.data

      const featuredBysResponse = await getOrcharcCoreFeaturedBys()
      this.featuredBys = featuredBysResponse.data

      this.pageDataLoaded = true
    } catch {
      this.pageDataLoaded = false
    }
  },
  metaInfo: {
    title: 'Benefit Buddy - Home'
  }
}
</script>

<style lang="scss" scoped>
.home {
  &__page {
    &-wrapper {
      position: relative;
    }

    &-foreground {
      position: relative;
      z-index: 2;
    }

    &-background {
      position: absolute;
      top: 0;
      width: 100%;
      height: 600px;
      background: $primary;
      z-index: 1;
    }
  }

  @include media-breakpoint-up(md) {
    &__page {
      &-background {
        clip-path: ellipse(80% 100% at 50% 0%);
      }
    }
  }
}
</style>
