<template>
  <div class="page-load-error">
    <div class="alert alert-danger text-center">
      There was an error loading this page. Please try again. If the issue persists <a href="#">contact support</a>.
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageLoadError'
}
</script>

<style lang="scss" scoped>
.page-load-error {
  height: 300px;
  margin-top: 6rem;
}
</style>
