<template>
  <b-img
    :src="$oc.buildMediaUrl(featuredByLocalization.logo.urls[0])"
    :alt="featuredByLocalization.logoAltText"
    fluid
  />
</template>

<script>
export default {
  name: 'FeaturedBy',
  props: {
    featuredBy: { type: Object, default: null, required: true }
  },
  computed: {
    featuredByLocalization () {
      return this.$oc.getSelectedLocalization(this.featuredBy.localization)
    }
  }
}
</script>

<style>

</style>
