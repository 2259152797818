import Vue from 'vue'
import Vuex from 'vuex'

// vuex modules
import moduleApp from './modules/app'
import moduleHeader from './modules/header'
import moduleProcedureCategory from './modules/procedure-category'
import moduleProcedureDetail from './modules/procedure-detail'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app: moduleApp,
    header: moduleHeader,
    procedureCategory: moduleProcedureCategory,
    procedureDetail: moduleProcedureDetail
  }
})
