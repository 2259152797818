<template>
  <div class="testimonial-item mb-3">
    <div class="testimonial-item__float-quote">
      &ldquo;
    </div>
    <div class="row mb-4">
      <div class="col testimonial-item__text">
        {{ testimonialLocalization.quote }}
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-5 col-lg-3 offset-lg-1">
        <img
          :src="$oc.buildMediaUrl(testimonialLocalization.personImage.urls[0])"
          class="img-fluid rounded-circle"
        >
      </div>
      <div class="col-7 col-lg-8 px-0">
        <div class="testimonial-item__person-name">
          {{ testimonialLocalization.personName }}
        </div>
        <div class="testimonial-item__procedure-name">
          {{ testimonialLocalization.personProcedure }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TestimonialItem',
  props: {
    testimonial: { type: Object, default: null, required: true }
  },
  computed: {
    testimonialLocalization () {
      return this.$oc.getSelectedLocalization(this.testimonial.localization)
    }
  }
}
</script>

<style lang="scss" scoped>
.testimonial-item {
  position: relative;

  &__float-quote {
    color: #6e00a5;
    font-family: serif;
    font-weight: 800;
    font-size: 5rem;
    line-height: 3rem;
    opacity: 0.35;
    text-align: center;
  }

  &__person-name {
    font-weight: bold;
    font-size: 1.4rem;
  }

  &__procedure-name {
    font-size: 1rem;
  }

  @include media-breakpoint-up(lg) {
    &__float-quote {
      font-size: 3.6rem;
      line-height: 2.8rem;
      left: 0;
      position: absolute;
      top: 20px;
    }

    &__text {
      padding: 20px 0 0 50px;
    }
  }
}
</style>
