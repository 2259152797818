<template>
  <header class="header sticky-top">
    <nav class="navbar navbar-expand-lg header__navbar d-flex justify-content-lg-center">
      <router-link
        :to="{ name: routes.HOME_PAGE }"
        class="navbar-brand header__navbar-brand my-0 mr-md-auto"
      >
        <brand-logo />
      </router-link>
      <div
        v-if="subscriberLogo"
        class="d-lg-none flex-fill text-center"
      >
        <img
          :src="subscriberLogo"
          alt="Logo - Subscriber"
          class="header__bio-news-logo"
        >
      </div>
      <nav-bar-toggler
        :expand-nav-collapse="expandNavCollapse"
        @clicked="expandNavCollapse = !expandNavCollapse"
      />
      <div
        v-if="subscriberLogo"
        class="d-none d-lg-block flex-fill text-right"
      >
        <img
          :src="subscriberLogo"
          alt="Logo - Subscriber"
          class="header__bio-news-logo"
        >
      </div>
      <div :class="navCollapseClasses">
        <nav-menu />
      </div>
    </nav>
  </header>
</template>

<script>
import BrandLogo from '../BrandLogo'
import NavMenu from './NavMenu'
import NavBarToggler from './NavBarToggler'
import benefitsBuddyLogo from '../../../assets/img/benefits-buddy-logo-white.png'
import { HOME_PAGE } from '../../../router/routes.types'
import { CommonBands } from 'vue-media-queries'

export default {
  name: 'Header',
  components: {
    BrandLogo,
    NavMenu,
    NavBarToggler
  },
  mixins: [
    CommonBands.Bootstrap4.mixin
  ],
  data () {
    return {
      benefitsBuddyLogo,
      expandNavCollapse: false,
      routes: {
        HOME_PAGE
      }
    }
  },
  computed: {
    navCollapseClasses () {
      return {
        'navbar-collapse': true,
        'mt-2': true,
        collapse: this.isMdDown && this.expandNavCollapse === false
      }
    },
    signedInUserSubscriberContent () {
      return this.$store.state.app.signedInUserSubscriberContent
    },
    subscriberLogo () {
      if (this.signedInUserSubscriberContent !== null && this.signedInUserSubscriberContent.subscriberLogo !== null) {
        return this.$oc.buildMediaUrl(this.signedInUserSubscriberContent.subscriberLogo.urls[0])
      }

      return null
    }
  },
  watch: {
    $route (to, from) {
      this.expandNavCollapse = false
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  filter: drop-shadow(0 2px 5px rgba(203, 204, 246, 0.5));
  z-index: $sticky-zindex + 20;

  &__navbar {
    background-color: $white;
    padding-top: .7rem;
    padding-bottom: .7rem;

    &-brand {
      text-decoration: none;
      margin-right: 0;
    }

    &::v-deep .nav-link,
    &::v-deep .navbar-text {
      color: $black;
      font-size: 18px;
      font-weight: 600;
      text-decoration: none;

      &:hover {
        color: $brand-purple-2;
      }
    }
  }

  &__search {
    &-icon {
      cursor: pointer;
      color: $white;
      font-size: 2rem;
    }
  }

  &__collapse-menu a {
    color: $white;
    font-size: 1.4rem;
  }

  @include media-breakpoint-up(lg) {
    &__navbar {
      padding: .4rem 1rem;

      &::v-deep .nav-link,
      &::v-deep .navbar-text {
        font-size: 14px;
      }
    }
  }

  &__bio-news-logo {
    width: 120px;

    @include media-breakpoint-up(md) {
      width: 170px;
    }

    @include media-breakpoint-up(xl) {
      margin-left: 14rem; // TODO: make this less hacky so the logo image is centered in the header without it
    }
  }
}
</style>
