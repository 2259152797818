<template>
  <form class="form">
    <div class="form-group">
      <b-form-input
        v-model.trim="$v.loginRequest.username.$model"
        type="text"
        class="form-control"
        placeholder="Username"
        aria-label="Username"
        :state="validateState('username')"
      />
      <b-form-invalid-feedback id="input-appointment-username">
        A valid email is required.
      </b-form-invalid-feedback>
    </div>
    <div class="form-group">
      <b-form-input
        v-model.trim="$v.loginRequest.password.$model"
        type="password"
        class="form-control"
        placeholder="Password"
        aria-label="Password"
        :state="validateState('password')"
      />
      <b-form-invalid-feedback id="input-appointment-password">
        Password is required.
      </b-form-invalid-feedback>
    </div>
    <div class="form-group text-center">
      <button
        type="button"
        class="btn btn-primary btn-lg px-4 font-weight-bold"
        @click="handleLoginClick"
      >
        Submit
      </button>
    </div>
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'
import { signInUser } from '../../../lib/userSignInApi'
import { mutationTypes, actionTypes } from '../../../store/modules/app'

export default {
  name: 'UserSignInForm',
  mixins: [validationMixin],
  data () {
    return {
      loginRequest: {
        username: null,
        password: null
      }
    }
  },
  methods: {
    validateState (name) {
      const { $dirty, $error } = this.$v.loginRequest[name]
      return $dirty ? !$error : null
    },
    handleLoginClick () {
      this.$v.$touch()

      if (this.$v.$invalid === false) {
        const signedInUserId = signInUser(this.loginRequest.username, this.loginRequest.password)

        if (signedInUserId !== undefined) {
          this.$store.dispatch(actionTypes.APP__SIGN_IN_USER, signedInUserId)
          this.$emit('user-signed-in')
        } else {
          this.$store.commit(mutationTypes.APP__SET_ALERT, { msg: 'Sign-in credentials invalid. Please try again.', variant: 'warning' })
        }
      }
    }
  },
  validations: {
    loginRequest: {
      username: {
        email,
        required
      },
      password: {
        required
      }
    }
  }
}
</script>

<style>

</style>
