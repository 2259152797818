const PROVIDER_FILTER_KEYS = {
  ALL_PROVIDERS: 'All Providers',
  VERIFIED_PROVIDERS: 'Preferred Providers'
}

const sorters = [
  {
    label: 'Price - Low to High',
    direction: 'asc',
    sortFunction: (locationItem) => {
      if (locationItem.procedureBundle !== null) {
        return locationItem.procedureBundle.feeSingle
      } else if (location.facility !== null) {
        return locationItem.facility.priceLevel
      }
    }
  },
  {
    label: 'Price - High to Low',
    direction: 'desc',
    sortFunction: (locationItem) => {
      if (locationItem.procedureBundle !== null) {
        return locationItem.procedureBundle.feeSingle
      } else if (location.facility !== null) {
        return locationItem.facility.priceLevel
      }
    }
  },
  {
    label: 'Distance - Closest',
    direction: 'asc',
    sortFunction: (locationItem) => {
      if (locationItem.physician !== null && locationItem.physician.distanceFromSelectedLocation !== null) {
        return locationItem.physician.distanceFromSelectedLocation
      } else if (locationItem.facility !== null && locationItem.facility.distanceFromSelectedLocation !== null) {
        return locationItem.facility.distanceFromSelectedLocation
      }
    }
  }
]

const distanceFilters = [
  {
    label: '5',
    maxDistanceMiles: 5
  },
  {
    label: '10',
    maxDistanceMiles: 10
  },
  {
    label: '25',
    maxDistanceMiles: 25
  },
  {
    label: '50',
    maxDistanceMiles: 50
  },
  {
    label: '100',
    maxDistanceMiles: 100
  },
  {
    label: '200',
    maxDistanceMiles: 200
  },
  {
    label: '500',
    maxDistanceMiles: 500
  }
]

const providerTypeFilters = [
  {
    label: 'All Providers',
    key: PROVIDER_FILTER_KEYS.ALL_PROVIDERS
  },
  {
    label: 'Preferred Providers',
    key: PROVIDER_FILTER_KEYS.VERIFIED_PROVIDERS
  }
]

export default {
  PROVIDER_FILTER_KEYS,
  distanceFilters,
  providerTypeFilters,
  sorters
}
