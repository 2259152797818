<template>
  <div class="orchardcore-content">
    <vue-markdown
      v-if="show"
      :source="text"
      :data-orchardcore-contentitem="item.alias"
      :postrender="markdownPostRender"
      :linkify="false"
    />
  </div>
</template>

<script>
import { sprintf } from 'sprintf-js'
import VueMarkdown from 'vue-markdown'
import orchardCoreComponentMixin from '../mixins/OrchardCoreComponent'

export default {
  name: 'OrchardcoreTextMarkdown',
  components: {
    VueMarkdown
  },
  mixins: [orchardCoreComponentMixin],
  props: {
    item: { type: Object, default: null },
    data: { type: Object, default: null },
    renderInline: { type: Boolean, default: false, required: false }
  },
  computed: {
    show () {
      return this.item !== undefined && this.item !== null
    },
    text () {
      const itemText = this.selectedLocalization.markdown

      if (this.data === null) {
        return itemText
      }

      let result

      try {
        result = sprintf(itemText, this.data)
      } catch (e) {
        result = itemText
      }

      return result
    }
  },
  methods: {
    markdownPostRender (htmlString) {
      if (this.renderInline === true) { // if rendering inline remove the wrapping <p>...</p> tag that Markdown renders by default
        return htmlString.replace(/^<p>/, '').replace(/<\/p>$/, '')
      }

      return htmlString
    }
  }
}
</script>

<style>
.orchardCore-content {
  display: inline;
}
</style>
