<template>
  <div class="location-typeahead-select">
    <div
      v-if="!selectedLocation"
      class="location-typeahead-select__typeahead"
    >
      <input
        ref="locationTypeaheadInput"
        v-model.trim="locationQuery"
        type="search"
        class="form-control border-0 rounded-0"
        name="location"
        placeholder="Zip Code"
        autocomplete="off"
      >
      <div
        v-if="locationSearchResults.length > 0"
        class="location-typeahead-select__search-results-dropdown"
      >
        <div
          v-for="(searchResult, searchResultIndex) in locationSearchResults"
          :key="searchResultIndex"
          class="location-typeahead-select__search-results-dropdown-item border-bottom p-2 js-exclude-search-procedure-location-click"
          @click="handleLocationSearchResultClick(searchResult)"
        >
          {{ searchResult.zipCodeId }} - {{ searchResult.city }}, {{ searchResult.stateAbbreviation }}
        </div>
      </div>
    </div>
    <div
      v-if="selectedLocation"
      class="row no-gutters align-items-center p-1"
      @click="handleSelectionLocationClick"
    >
      <div class="col p-1 location-typeahead-select__selected-item">
        {{ selectedLocation.zipCodeId }} - {{ selectedLocation.city }}, {{ selectedLocation.stateAbbreviation }}
      </div>
      <div class="col-auto">
        <button
          type="button"
          class="btn btn-secondary location-typeahead-select__clear-selected-btn"
        >
          <font-awesome-icon icon="times" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, debounce } from 'lodash'
import { getLocationSearch } from '../../../lib/api'

export default {
  name: 'LocationTypeAheadSelect',
  props: {
    selectedLocation: { type: Object, default: null, required: false }
  },
  data () {
    return {
      locationQuery: null,
      locationSearchResults: []
    }
  },
  watch: {
    locationQuery (v, ov) {
      if (v !== null && v.length >= 2) {
        this.searchLocations()
      } else if (v !== null && v.length === 0) {
        this.locationSearchResults = []
      }
    }
  },
  methods: {
    searchLocations: debounce(async function () {
      if (this.locationQuery && this.locationQuery.length > 0) {
        const searchApiResponse = await getLocationSearch(this.locationQuery)
        this.locationSearchResults = searchApiResponse.data
      } else {
        this.locationSearchResults = []
      }
    }, 500),
    handleLocationSearchResultClick (selectedLocation) {
      this.$emit('location-selected', cloneDeep(selectedLocation))
      this.resetUi()
    },
    resetUi () {
      this.locationQuery = null
      this.locationSearchResults = []
    },
    focusInput () {
      this.$nextTick(() => {
        this.$refs.locationTypeaheadInput.focus()
      })
    },
    handleSelectionLocationClick () {
      this.$emit('clear-selected')
      this.focusInput()
    }
  }
}
</script>

<style lang="scss" scoped>
.location-typeahead-select {
  @include typeahead-select;
}
</style>
