<template>
  <div>
    <carousel
      :per-page="1"
      :navigation-enabled="false"
      :pagination-enabled="false"
      :value="displaySlide"
      class="mb-4"
    >
      <slide
        v-for="(testimonialsChunk, tChunkIndex) in testimonialsChunked"
        :key="tChunkIndex"
      >
        <div class="row">
          <div
            v-for="(testimonial, tIndex) in testimonialsChunk"
            :key="tIndex"
            class="col-4"
          >
            <testimonial-item :testimonial="testimonial" />
          </div>
        </div>
      </slide>
    </carousel>
    <desktop-carousel-pagination
      :number-pages="testimonialsChunked.length"
      :displayed-slide-index="displaySlide"
      @clicked-previous="handleCarouselPrevClick"
      @clicked-next="handleCarouselNextClick"
      @handle-page-clicked="handlePageClick"
    />
  </div>
</template>

<script>
import DesktopCarouselPagination from './Pagination'
import TestimonialItem from '../TestimonialItem'
import { Carousel, Slide } from 'vue-carousel'
import { chunk } from 'lodash'

export default {
  name: 'DesktopCarousel',
  components: {
    Carousel,
    DesktopCarouselPagination,
    Slide,
    TestimonialItem
  },
  props: {
    testimonials: { type: Array, default: function () { return [] }, required: true },
    pageSize: { type: Number, default: null, required: true }
  },
  data () {
    return {
      displaySlide: 0
    }
  },
  computed: {
    testimonialsChunked () {
      return chunk(this.testimonials, this.pageSize)
    }
  },
  methods: {
    handleCarouselPrevClick () {
      if (this.displaySlide > 0) {
        this.displaySlide--
      } else {
        this.displaySlide = this.testimonialsChunked.length - 1
      }
    },
    handleCarouselNextClick () {
      if (this.displaySlide < this.testimonialsChunked.length - 1) {
        this.displaySlide++
      } else {
        this.displaySlide = 0
      }
    },
    handlePageClick (slideIndex) {
      this.displaySlide = slideIndex - 1
    }
  }
}
</script>

<style lang="scss" scoped>
.desktop-carousel {
  &__control {
    font-size: 3rem;
  }
}
</style>
