<template>
  <div class="page-loading text-center">
    <font-awesome-icon
      icon="spinner"
      spin
      class="page-loading__icon"
    />
  </div>
</template>

<script>
export default {
  name: 'PageLoading'
}
</script>

<style lang="scss" scoped>
.page-loading {
  height: 300px;
  margin-top: 4rem;

  &__icon {
    font-size: 2rem;
  }
}
</style>
