<template>
  <b-nav-item-dropdown
    v-if="signedInUser"
    :html="dropdownHtml"
    class="px-4 signed-in-user-nav"
    menu-class="signed-in-user-nav__dropdown-menu"
  >
    <b-dropdown-item
      class="dropdown-list-item"
      href="#"
      @click="handleLogoutLinkClick"
    >
      Logout
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { actionTypes } from '../../../store/modules/app'

export default {
  name: 'SignedInUserNav',
  props: {
    username: { type: String, default: null, required: true }
  },
  data () {
    return {
    }
  },
  computed: {
    signedInUser () {
      return this.$store.state.app.signedInUser
    },
    dropdownHtml () {
      return `<svg data-v-10ced804="" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-user fa-w-14"><path data-v-10ced804="" fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z" class=""></path></svg>&nbsp;&nbsp;${this.signedInUser.firstName}`
    }
  },
  methods: {
    handleLogoutLinkClick () {
      this.$store.dispatch(actionTypes.APP__SIGN_OUT_USER)
    }
  }
}
</script>

<style lang="scss" scoped>
// TODO - DRY up the SASS between this component and ResourcesNavDesktop.vue component
.signed-in-user-nav {
  &::v-deep .dropdown-list-item {
    border-bottom: 1px solid $brand-light-gray-1;

    &:last-child {
      border-bottom: none;
    }
  }

  &::v-deep .nav-link {
    outline: none;
  }

  &::v-deep .dropdown-item {
    color: $black;
    font-size: 18px;
    font-weight: normal;
    padding: 0.5rem 0.75rem;
    text-decoration: none;

    &:hover {
      color: $brand-purple-2;
    }
  }

  &::v-deep &__dropdown-menu {
    outline: none;
    padding: 0;
    filter: drop-shadow(0 4px 0.25rem $brand-medium-gray-1);
  }

  @include media-breakpoint-up(lg) {
    &::v-deep .dropdown-item {
      font-size: 14px;
    }
  }
}
</style>
