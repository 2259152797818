<template>
  <div class="orchardcore-content">
    <oc-text
      v-if="isText"
      :item="item"
    />
    <oc-text-md
      v-if="isTextMarkdown"
      :item="item"
    />
    <oc-image
      v-if="isImage"
      :item="item"
    />
    <div
      v-if="itemNotFound"
      class="alert alert-danger"
    >
      Missing CMS item
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrchardCoreContent',
  props: {
    item: { type: Object, default: null, required: true },
    data: { type: Object, default: null, required: false }
  },
  computed: {
    itemNotFound () {
      return this.item === null && process.env.NODE_ENV === 'development'
    },
    isText () {
      if (this.item === null || this.item === undefined) {
        return null
      }

      return this.item.contentType === 'ContentModuleTextUnformatted'
    },
    isTextMarkdown () {
      if (this.item === null || this.item === undefined) {
        return null
      }

      return this.item.contentType === 'ContentModuleTextMarkdown'
    },
    isImage () {
      if (this.item === null || this.item === undefined) {
        return null
      }

      return this.item.contentType === 'ContentModuleImage'
    }
  }
}
</script>

<style lang="scss" scoped>
.orchardcore-content {
  display: inline;
}
</style>
