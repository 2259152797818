<template>
  <div class="text-center cta-button__wrapper">
    <button
      type="button"
      class="btn btn-default cta-button"
      @click="handleCtaClick"
    >
      <oc-content :item="$oc.content.homePageJumbotronCtaLabel" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'CtaButton',
  methods: {
    handleCtaClick () {
      this.$scrollTo('#jumbotron', 200, { offset: -130 })
    }
  }
}
</script>

<style lang="scss" scoped>
.cta-button {
  background-color: $brand-green-1;
  border-radius: 37px;
  padding: 1rem 3rem;
  filter: drop-shadow(0 0.5rem 0.5rem rgba(71, 227, 161, 0.5));
  font-weight: bold;
  font-size: 1.4rem;

  &__wrapper {
    margin: 4rem 0;
  }
}
</style>
