<template>
  <div class="procedure-typeahead-select">
    <div
      v-if="!selectedProcedure"
      class="procedure-typeahead-select__typeahead"
    >
      <input
        ref="procedureTypeaheadInput"
        v-model.trim="procedureQuery"
        type="search"
        class="form-control border-0 rounded-0"
        name="procedure"
        placeholder="needed care or procedure"
        autocomplete="off"
      >
      <div
        v-if="procedureSearchResults.length > 0"
        class="procedure-typeahead-select__search-results-dropdown"
      >
        <div
          v-for="(searchResult, searchResultIndex) in procedureSearchResults"
          :key="searchResultIndex"
          class="procedure-typeahead-select__search-results-dropdown-item border-bottom p-2 js-exclude-search-procedure-location-click"
          @click="handleProcedureSearchResultClick(searchResult)"
        >
          {{ searchResult.name }}
        </div>
      </div>
    </div>
    <div
      v-if="selectedProcedure"
      class="row no-gutters align-items-center p-1"
      @click="handleSelectedProcedureClick"
    >
      <div class="col p-1 procedure-typeahead-select__selected-item">
        {{ selectedProcedure.name }}
      </div>
      <div class="col-auto">
        <button
          type="button"
          class="btn btn-secondary procedure-typeahead-select__clear-selected-btn"
        >
          <font-awesome-icon icon="times" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, debounce } from 'lodash'
import { getProcedureSearch } from '../../../lib/api'

export default {
  name: 'ProcedureTypeAheadSelect',
  props: {
    selectedProcedure: { type: Object, default: null, required: false }
  },
  data () {
    return {
      procedureQuery: null,
      procedureSearchResults: []
    }
  },
  watch: {
    procedureQuery (v, ov) {
      if (v !== null && v.length >= 3) {
        this.searchProcedures()
      } else if (v !== null && v.length === 0) {
        this.procedureSearchResults = []
      }
    }
  },
  methods: {
    searchProcedures: debounce(async function () {
      if (this.procedureQuery && this.procedureQuery.length > 0) {
        const searchApiResponse = await getProcedureSearch(this.procedureQuery)
        this.procedureSearchResults = searchApiResponse.data
      } else {
        this.procedureSearchResults = []
      }
    }, 500),
    handleProcedureSearchResultClick (searchResult) {
      this.$emit('procedure-selected', cloneDeep(searchResult))
      this.resetUi()
    },
    resetUi () {
      this.procedureQuery = null
      this.procedureSearchResults = []
    },
    focusInput () {
      this.$nextTick(() => {
        this.$refs.procedureTypeaheadInput.focus()
      })
    },
    handleSelectedProcedureClick () {
      this.$emit('clear-selected')
      this.focusInput()
    }
  }
}
</script>

<style lang="scss" scoped>
.procedure-typeahead-select {
  @include typeahead-select;
}
</style>
