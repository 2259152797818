<template>
  <b-modal
    v-model="showModal"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    :hide-footer="true"
    :centered="true"
    title="User Sign-in"
  >
    <div class="row">
      <div class="col col-md-8 offset-md-2">
        <user-sign-in-form @user-signed-in="showModal = false" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import UserSignInForm from './UserSignInForm'

export default {
  name: 'UserSignInModal',
  components: {
    UserSignInForm
  },
  data () {
    return {
      showModal: false
    }
  },
  methods: {
    setShowModal (v) {
      this.showModal = v
    }
  }
}
</script>

<style>

</style>
