<template>
  <div class="desktop-carousel-pagination row align-items-center justify-content-center">
    <div class="desktop-carousel-pagination__column desktop-carousel-pagination__column--left col-auto text-right">
      <font-awesome-icon
        class="desktop-carousel__control"
        :icon="['fal', 'long-arrow-left']"
        @click="$emit('clicked-previous')"
      />
    </div>
    <div class="desktop-carousel-pagination__column desktop-carousel-pagination__column--center col-auto text-center">
      <div
        v-for="slideIndex in numberPages"
        :key="slideIndex"
        class="d-inline-block p-1"
      >
        <font-awesome-icon
          :icon="getPaginationIcon(slideIndex)"
          :class="getPaginationIconClass(slideIndex)"
          @click="$emit('handle-page-clicked', slideIndex)"
        />
      </div>
    </div>
    <div class="desktop-carousel-pagination__column desktop-carousel-pagination__column--right col-auto text-left">
      <font-awesome-icon
        :icon="['fal', 'long-arrow-right']"
        class="desktop-carousel__control"
        @click="$emit('clicked-next')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DesktopCarouselPagination',
  props: {
    displayedSlideIndex: { type: Number, default: 0, required: true },
    numberPages: { type: Number, default: 0, required: true }
  },
  methods: {
    getPaginationIcon (slideIndex) {
      if ((slideIndex - 1) === this.displayedSlideIndex) {
        return ['fas', 'circle']
      } else {
        return ['far', 'circle']
      }
    },
    handlePaginationIconClick (slideIndex) {
      this.displayedSlideIndex = slideIndex - 1
    },
    getPaginationIconClass (slideIndex) {
      const classes = ['desktop-carousel-pagination__icon']

      if ((slideIndex - 1) === this.displayedSlideIndex) {
        classes.push('desktop-carousel-pagination__icon--selected')
      }

      return classes
    }
  }
}
</script>

<style lang="scss" scoped>
.desktop-carousel-pagination {
    &__column {
      background-color: rgba(129,224,244,0.1);
      padding-top: 12px;
      padding-bottom: 12px;

      &--left {
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
      }

      &--center {
        padding: 8px 0;
      }

      &--right {
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
      }
    }

    &__icon {
      cursor: pointer;

      &--selected {
        color: $brand-green-2;
        cursor: default;
      }
    }
  }
</style>
