<template>
  <footer class="border-top footer">
    <div class="container-fluid">
      <div class="row mt-4">
        <div class="col-12 col-md-3 mb-2">
          <brand-logo
            font-size="24px"
            class="mb-2"
          />
          <div>
            <address class="mb-2">
              200 E Government Street<br>
              Suite 130<br>
              Pensacola, FL 32502
            </address>
            <div>
              {{ benefitBuddyPhoneNumber }}
            </div>
            <div class="mb-2">
              <a href="mailto:service@benefitbuddy.com">service@benefitbuddy.com</a>
            </div>
          </div>
        </div>
        <div class="col-12 offset-md-3 col-md-6 offset-xxl-4 col-xxl-5 mb-2">
          <div class="row">
            <div class="col-12 col-lg">
              <div class="mb-3">
                <router-link
                  :to="{ name: routes.PRIVACY_PAGE }"
                  class="d-block d-md-inline text-center text-md-left"
                >
                  Privacy
                </router-link>
              </div>
              <div class="mb-3">
                <router-link
                  :to="{ name: routes.TERMS_PAGE }"
                  class="d-block d-md-inline text-center text-md-left"
                >
                  Terms of Use
                </router-link>
              </div>
            </div>
            <div class="col-12 col-lg">
              <div class="mb-3">
                <router-link
                  :to="{ name: routes.ABOUT_PAGE }"
                  class="d-block d-md-inline text-center text-md-left"
                >
                  About Us
                </router-link>
              </div>
              <div class="mb-3">
                <router-link
                  :to="{ name: routes.CONTACT_PAGE }"
                  class="d-block d-md-inline text-center text-md-left"
                >
                  Contact
                </router-link>
              </div>
            </div>
            <div class="col-12 col-lg">
              <div class="mb-3">
                <router-link
                  :to="{ name: routes.PROVIDER_RESOURCES_PAGE }"
                  class="d-block d-md-inline text-center text-md-left"
                >
                  Provider Resources
                </router-link>
              </div>
              <div class="mb-3">
                <router-link
                  :to="{ name: routes.EMPLOYER_RESOURCES_PAGE }"
                  class="d-block d-md-inline text-center text-md-left"
                >
                  Employer Resources
                </router-link>
              </div>
              <div class="mb-3">
                <router-link
                  :to="{ name: routes.BROKER_RESOURCES_PAGE }"
                  class="d-block d-md-inline text-center text-md-left"
                >
                  Broker Resources
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import BrandLogo from './BrandLogo'
import { HOME_PAGE, ABOUT_PAGE, PRIVACY_PAGE, TERMS_PAGE, PROVIDER_RESOURCES_PAGE, EMPLOYER_RESOURCES_PAGE, BROKER_RESOURCES_PAGE, CONTACT_PAGE } from '../../router/routes.types'

export default {
  name: 'Footer',
  components: {
    BrandLogo
  },
  data () {
    return {
      routes: {
        ABOUT_PAGE,
        BROKER_RESOURCES_PAGE,
        CONTACT_PAGE,
        EMPLOYER_RESOURCES_PAGE,
        HOME_PAGE,
        PRIVACY_PAGE,
        PROVIDER_RESOURCES_PAGE,
        TERMS_PAGE
      }
    }
  },
  computed: {
    benefitBuddyPhoneNumber () {
      return this.$store.state.app.benefitBuddyPhoneNumber
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  background-color: $brand-purple-1;
  color: $white;
  font-size: 15px;
  padding-bottom: 3rem;
  position: relative;
  z-index: 3;

  a {
    color: $white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
  }
}
</style>
