import { mutationTypes } from '../../store/modules/app'

export default {
  computed: {
    pageDataLoaded: {
      get () {
        return this.$store.state.app.pageDataLoaded
      },
      set (v) {
        this.$nextTick(() => {
          this.$store.commit(mutationTypes.APP__SET_PAGE_DATA_LOADED, v)
        })
      }
    }
  }
}
