// prefix all getters, mutations and actions with PROCEDURE_CATEGORY__/procedureCategory_ to "namespace" them

export const mutationTypes = {
  PROCEDURE_CATEGORY__SET_PAGE_DATA: 'procedureCategory_SetPageData'
}

export const getterTypes = {}
export const actionTypes = {}

export default {
  state: {
    pageData: null
  },
  mutations: {
    [mutationTypes.PROCEDURE_CATEGORY__SET_PAGE_DATA]: (state, value) => {
      state.pageData = value
    }
  }
}
