import routeTypes from './routes.types'

import Home from '../pages/Home'
// dynamically loaded components. only loaded if they are called by the router.
const About = () => import('../pages/About')
const BrokerResources = () => import('../pages/Resources/BrokerResources')
const Contact = () => import('../pages/Contact')
const EmployerResources = () => import('../pages/Resources/EmployerResources')
const Privacy = () => import('../pages/Privacy')
const ProcedureCategory = () => import('../pages/Procedures/Category')
const ProcedureDetail = () => import('../pages/Procedures/Detail')
const ProviderResources = () => import('../pages/Resources/ProviderResources')
const Terms = () => import('../pages/Terms')

export default [
  { path: '/app', component: Home, name: routeTypes.HOME_PAGE },
  { path: '/app/about', component: About, name: routeTypes.ABOUT_PAGE },
  { path: '/app/broker-resources', component: BrokerResources, name: routeTypes.BROKER_RESOURCES_PAGE },
  { path: '/app/contact', component: Contact, name: routeTypes.CONTACT_PAGE },
  { path: '/app/employer-resources', component: EmployerResources, name: routeTypes.EMPLOYER_RESOURCES_PAGE },
  { path: '/app/privacy', component: Privacy, name: routeTypes.PRIVACY_PAGE },
  { path: '/app/procedure/category/:categoryId', component: ProcedureCategory, name: routeTypes.PROCEDURE_CATEGORY_PAGE },
  { path: '/app/procedure/detail/:procedureId/:zipCode', component: ProcedureDetail, name: routeTypes.PROCEDURE_DETAIL_PAGE },
  { path: '/app/provider-resources', component: ProviderResources, name: routeTypes.PROVIDER_RESOURCES_PAGE },
  { path: '/app/terms', component: Terms, name: routeTypes.TERMS_PAGE }
]
