export const mutationTypes = {
}

export const getterTypes = {}

export const actionTypes = {}

export default {
  state: {
  },
  mutations: {
  }
}
