<template>
  <div class="faq">
    <div class="container">
      <h2 class="text-center mb-4 faq__header">
        <oc-content :item="$oc.content.homePageFaqTitle" />
        <dots-ornament />
      </h2>
      <div class="faq__items">
        <faq-item
          v-for="(faq, faqIndex) in faqsSorted"
          :key="faqIndex"
          :faq="faq"
          :is-selected="selectedFaq === faqIndex"
          @selected="handleFaqSelected(faqIndex)"
        />
      </div>
    </div>
  </div>
  <!-- TODO can remove from CMS
    $oc.content.homePageFaqCtaLabel
  -->
</template>

<script>
import DotsOrnament from '../../Shared/DotsOrnament'
import FaqItem from './FaqItem'

import { orderBy } from 'lodash'

export default {
  name: 'FaqSection',
  components: {
    DotsOrnament,
    FaqItem
  },
  props: {
    faqs: { type: Array, required: true, default: function () { return [] } }
  },
  data () {
    return {
      selectedFaq: null
    }
  },
  computed: {
    faqsSorted () {
      return orderBy(this.faqs, 'sortOrder')
    }
  },
  methods: {
    handleFaqSelected (faqIndex) {
      if (this.selectedFaq === faqIndex) {
        this.selectedFaq = null
      } else {
        this.selectedFaq = faqIndex
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.faq {
  &__header {
    font-size: 1.8rem;
    font-weight: 600;
  }

  &__items {
    margin-bottom: 3rem;
  }

  @include media-breakpoint-up(md) {
    padding: 4rem 0 2rem 0;
  }
}
</style>
