<template>
  <div class="how-works-item">
    <h4 class="how-works-item__title">
      {{ item.title }}
    </h4>
    <div class="how-works-item__lede">
      <vue-markdown>{{ item.lede }}</vue-markdown>
    </div>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'

export default {
  name: 'HowWorksListItem',
  components: { VueMarkdown },
  props: {
    item: { type: Object, default: null, required: true }
  }
}
</script>

<style lang="scss" scoped>
.how-works-item {
  margin: 0 0 2rem 0;
  text-align: left;

  &__title {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }

  &__lede {
    font-size: 0.8rem;
  }

  @include media-breakpoint-up(md) {
    margin: 0;
    text-align: center;

    &__title {
      margin: 0 0 10px 0;
    }

    &__lede {
      font-size: 1rem;
      padding: 1rem 1rem 0 1rem;
    }
  }

  @include media-breakpoint-up(lg) {
    &__lede {
      font-size: 1rem;
      padding: 0;
    }
  }
}
</style>
