<template>
  <ul class="navbar-nav">
    <li class="nav-item">
      <router-link
        :to="{ name: routes.PROVIDER_RESOURCES_PAGE }"
        class="nav-link resources-nav-mobile__nav-link"
      >
        Provider
      </router-link>
    </li>
    <li class="nav-item">
      <router-link
        :to="{ name: routes.EMPLOYER_RESOURCES_PAGE }"
        class="nav-link resources-nav-mobile__nav-link"
      >
        Employer
      </router-link>
    </li>
    <li class="nav-item">
      <router-link
        :to="{ name: routes.BROKER_RESOURCES_PAGE }"
        class="nav-link resources-nav-mobile__nav-link"
      >
        Broker
      </router-link>
    </li>
  </ul>
</template>

<script>
import { PROVIDER_RESOURCES_PAGE, EMPLOYER_RESOURCES_PAGE, BROKER_RESOURCES_PAGE } from '../../../router/routes.types'

export default {
  name: 'ResourcesNavMobile',
  data () {
    return {
      routes: {
        PROVIDER_RESOURCES_PAGE,
        EMPLOYER_RESOURCES_PAGE,
        BROKER_RESOURCES_PAGE
      }
    }
  }
}
</script>

<style lang="scss" scopec>
.resources-nav-mobile {
  &__nav-link {
      font-size: 16px !important;
      font-weight: normal !important;
    }
}
</style>
