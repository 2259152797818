<template>
  <div>
    <b-carousel
      ref="testimonialCarousel"
      v-model="testimonialSlide"
      :interval="6000"
    >
      <testimonial-carousel-slide
        v-for="(testimonial, tIndex) in testimonials"
        :key="tIndex"
        :testimonial="testimonial"
      />
    </b-carousel>
    <div class="row">
      <div class="col-6 text-center">
        <font-awesome-icon
          class="mobile-carousel__control"
          :icon="['fal', 'long-arrow-left']"
          @click="handleCarouselPrevClick"
        />
      </div>
      <div class="col-6 text-center">
        <font-awesome-icon
          :icon="['fal', 'long-arrow-right']"
          class="mobile-carousel__control"
          @click="handleCarouselNextClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TestimonialCarouselSlide from './TestimonialCarouselSlide'
export default {
  name: 'MobileCarousel',
  components: {
    TestimonialCarouselSlide
  },
  props: {
    testimonials: { type: Array, default: function () { return [] }, required: true }
  },
  data () {
    return {
      testimonialSlide: 0
    }
  },
  methods: {
    handleCarouselPrevClick () {
      this.$refs.testimonialCarousel.prev()
    },
    handleCarouselNextClick () {
      this.$refs.testimonialCarousel.next()
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-carousel {
  &__control {
    font-size: 3rem;
  }
}
</style>
