<template>
  <div
    v-click-outside-component="handleClickOutsideComponent"
    :class="rootClasses"
  >
    <div :class="wrapperClasses">
      <div :class="procedureColumnClasses">
        <div class="row no-gutters align-items-center pt-2 pb-1 pr-2">
          <div class="col-auto ml-2">
            <label
              for="procedure"
              class="search-procedure-location__label"
            >Find</label>
          </div>
          <div class="col search-procedure-location__search-column">
            <procedure-type-ahead-select
              ref="procedureTypeAheadSelect"
              :selected-procedure="selectedProcedure"
              @procedure-selected="handleProcedureSelected"
              @clear-selected="handleClearSelectedProcedure"
            />
          </div>
        </div>
      </div>
      <div :class="locationColumnClasses">
        <div class="row no-gutters align-items-center pt-2 pb-1 pr-2">
          <div class="col-auto ml-2">
            <label
              for="location"
              class="search-procedure-location__label"
            >Near</label>
          </div>
          <div class="col">
            <location-type-ahead-select
              ref="locationTypeAheadSelect"
              :selected-location="selectedLocation"
              @location-selected="handleLocationSelected"
              @clear-selected="handleClearSelectedLocation"
            />
          </div>
        </div>
      </div>
      <div :class="ctaColumnClasses">
        <div class="search-procedure-location__cta-wrapper">
          <button
            type="button"
            class="btn search-procedure-location__cta"
            :disabled="ctaButtonDisbled"
            @click="handleCtaClick"
          >
            <img :src="svgSearchIcon">
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="displayMode === displayModes.featured"
      class="row d-md-none mt-2"
    >
      <div class="col">
        <button
          type="button"
          :disabled="ctaButtonDisbled"
          class="btn search-procedure-location__cta search-procedure-location__cta--mobile"
          @click="handleCtaClick"
        >
          <font-awesome-icon icon="search" />
          Find Providers
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mutationTypes as appMutationsTypes } from '../../../store/modules/app'
import { PROCEDURE_DETAIL_PAGE } from '../../../router/routes.types'
import LocationTypeAheadSelect from '../Search/LocationTypeAheadSelect'
import ProcedureTypeAheadSelect from '../Search/ProcedureTypeAheadSelect'
import Vue from 'vue'
import VueClickOutsideComponent from 'vue-click-outside-component'

import svgSearchIcon from '../../../assets/svg/search-icon.svg'

Vue.use(VueClickOutsideComponent)

export const displayModes = {
  featured: 'featured',
  inline: 'inline'
}

export default {
  name: 'SearchProcedureLocation',
  components: {
    LocationTypeAheadSelect,
    ProcedureTypeAheadSelect
  },
  props: {
    displayMode: { type: String, default: displayModes.inline, require: false }
  },
  data () {
    return {
      localSelectedProcedure: undefined,
      displayModes,
      svgSearchIcon
    }
  },
  computed: {
    rootClasses () {
      return {
        'search-procedure-location': true
      }
    },
    wrapperClasses () {
      return {
        row: true,
        'no-gutters': true,
        'search-procedure-location__wrapper': true,
        'search-procedure-location__wrapper--inline': this.displayMode === this.displayModes.inline,
        'search-procedure-location__wrapper--featured': this.displayMode === this.displayModes.featured
      }
    },
    procedureColumnClasses () {
      return {
        'search-procedure-location__column': true,
        'search-procedure-location__procedure-column': true,
        'search-procedure-location__procedure-column--inline': this.displayMode === this.displayModes.inline,
        'search-procedure-location__procedure-column--featured': this.displayMode === this.displayModes.featured,
        'col-12': true,
        'col-md-7': true
      }
    },
    locationColumnClasses () {
      return {
        'search-procedure-location__column': true,
        'search-procedure-location__location-column': true,
        'search-procedure-location__location-column--inline': this.displayMode === this.displayModes.inline,
        'search-procedure-location__location-column--featured': this.displayMode === this.displayModes.featured,
        col: this.displayMode === this.displayModes.inline,
        'col-12': this.displayMode === this.displayModes.featured,
        'col-md': true
      }
    },
    ctaColumnClasses () {
      return {
        'col-auto': true,
        'd-none': this.displayMode === this.displayModes.featured,
        'd-md-block': this.displayMode === this.displayModes.featured
      }
    },
    selectedProcedure () {
      if (this.localSelectedProcedure === undefined) {
        return this.$store.state.app.selectedProcedure
      } else {
        return this.localSelectedProcedure
      }
    },
    selectedLocation: {
      get () {
        return this.$store.state.app.selectedLocation
      },
      set (value) {
        this.$store.commit(appMutationsTypes.APP__SET_SELECTED_LOCATION, value)
      }
    },
    ctaButtonDisbled () {
      return !this.selectedProcedure || !this.selectedLocation
    }
  },
  methods: {
    handleProcedureSelected (data) {
      this.localSelectedProcedure = data
    },
    handleClearSelectedProcedure () {
      this.localSelectedProcedure = null
    },
    handleLocationSelected (data) {
      this.selectedLocation = data
    },
    handleClearSelectedLocation () {
      this.selectedLocation = null
    },
    handleCtaClick () {
      this.$router.push({ name: PROCEDURE_DETAIL_PAGE, params: { procedureId: this.selectedProcedure.procedureId, zipCode: this.selectedLocation.zipCodeId } })
    },
    handleClickOutsideComponent (ev) {
      if (ev.srcElement.classList.contains('js-exclude-search-procedure-location-click') === false) { // clicked elements that should not reset the search UI are marked with a `js-exclude-search-procedure-location-click` class
        if (this.$refs.procedureTypeAheadSelect !== undefined) {
          this.$refs.procedureTypeAheadSelect.resetUi()
        }

        if (this.$refs.locationTypeAheadSelect !== undefined) {
          this.$refs.locationTypeAheadSelect.resetUi()
        }
      }
    },
    focusProcedureInput () {
      this.$refs.procedureTypeAheadSelect.focusInput()
    }
  }
}
</script>

<style lang="scss" scoped>
.search-procedure-location {

  font-size: 16px;

  &__wrapper {
    background-color: $white;

    &--featured {
      border-radius: 9px;
      box-shadow: 0 2px 4px 0 #1f65b4;
    }

    &--inline {
      border-top: 1px solid $brand-purple-3;
      border-bottom: 1px solid $brand-purple-3;
    }
  }

  &__procedure-column {
    &--inline {
      border-bottom: 1px solid $brand-purple-3;
    }

    &--featured {
      border-bottom: 2px solid $primary;
    }
  }

  &__location-column {
    &--inline {
      border-right: 1px solid $brand-purple-3;
    }
  }

  &__label {
    margin-bottom: 0;
  }

  &__cta {
    &-wrapper {
      width: 100%;
      height: 100%;
    }

    border-radius: 0;
    color: $brand-purple-4;
    font-size: 1.4rem;
    margin: 0;
    padding: 4px 8px 0 4px;
    text-align: center;

    &--mobile {
      background-color: $brand-purple-4;
      border-color: transparent;
      border-radius: 6px;
      color: $white;
      font-size: 16px;
      font-weight: 600;
      padding: 0.5rem 0;
      width: 100%;

      &:disabled {
        opacity: 1;
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__column {
      border-right: 2px solid $primary;
    }

    &__procedure-column {
      border-bottom: none;
    }
  }

  @include media-breakpoint-up(lg) {
    &__wrapper {
      border-radius: 9px;
    }
  }
}
</style>
