<template>
  <div :class="rootClasses">
    <div class="row">
      <div class="col-8 offset-2">
        <h2 class="how-works__header text-center">
          <oc-content :item="$oc.content.homePageHowWorksTitle" />
        </h2>
        <dots-ornament class="text-center mb-4" />
      </div>
    </div>
    <div class="row">
      <div class="col-4 col-md-12">
        <div class="how-works__steps-image mb-2">
          <oc-content
            v-if="isSmDown"
            :item="$oc.content.homePageHowWorksStepsImageMobile"
          />
          <oc-content
            v-if="isMdUp"
            :item="$oc.content.homePageHowWorksStepsImage"
          />
        </div>
      </div>
      <div class="col-8 col-md-12">
        <div class="row">
          <div
            v-for="(howWorksListItem, howWorksListItemsIndex) in howWorksListItems"
            :key="howWorksListItemsIndex"
            class="col-12 col-md-4 mb-3"
          >
            <how-works-list-item :item="howWorksListItem" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- //TODO can remove from CMS
    $oc.content.homePageHowWorksEntry1Image
    $oc.content.homePageHowWorksEntry2Image
    $oc.content.homePageHowWorksEntry3Image
  -->
</template>

<script>
import { CommonBands } from 'vue-media-queries'
import DotsOrnament from '../../Shared/DotsOrnament'
import HowWorksListItem from './HowWorksListItem'

export default {
  name: 'HowWorksSection',
  components: { DotsOrnament, HowWorksListItem },
  mixins: [CommonBands.Bootstrap4.mixin],
  computed: {
    howWorksListItems () {
      return [
        {
          title: this.$oc.getSelectedLocalization(this.$oc.content.homePageHowWorksEntry1Title.localization).content,
          lede: this.$oc.getSelectedLocalization(this.$oc.content.homePageHowWorksEntry1Lede.localization).markdown
        },
        {
          title: this.$oc.getSelectedLocalization(this.$oc.content.homePageHowWorksEntry2Title.localization).content,
          lede: this.$oc.getSelectedLocalization(this.$oc.content.homePageHowWorksEntry2Lede.localization).markdown
        },
        {
          title: this.$oc.getSelectedLocalization(this.$oc.content.homePageHowWorksEntry3Title.localization).content,
          lede: this.$oc.getSelectedLocalization(this.$oc.content.homePageHowWorksEntry3Lede.localization).markdown
        }
      ]
    },
    rootClasses () {
      return {
        'how-works': true,
        container: this.isLgUp,
        'container-fluid': this.isMdDown
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.how-works {
  color: $white;
  margin-bottom: 3rem;
  background-image: url('../../../assets/img/how-works-background--mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2rem 1rem 0 1rem;

  &__header {
    font-size: 1.6rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }

  &__steps-image {
    text-align: right;
  }

  @include media-breakpoint-up(md) {
    padding: 3rem 6rem 1rem 6rem;

    &__header {
      font-size: 2rem;
    }

    &__steps-image {
      text-align: center;
      padding: 0 40px;
    }
  }

  @include media-breakpoint-up(lg) {
    background-image: url('../../../assets/img/how-works-background--desktop.png');
    background-size: contain;
    padding: 3rem 6rem;

    &__steps-image {
      padding: 0;
    }
  }
}
</style>
