import demoAccountLogins from './demoAccountLogins'
import { find } from 'lodash'

export function signInUser (username, password) {
  const foundUser = find(demoAccountLogins, { username, password })

  if (foundUser !== undefined && foundUser !== null) {
    return foundUser.id
  }

  return undefined
}
