<template>
  <div class="dots-ornament">
    <img :src="pngDotsOrnament">
  </div>
</template>

<script>
import pngDotsOrnament from '../../assets/img/dots-ornament.png'

export default {
  name: 'DotsOrnament',
  data () {
    return {
      pngDotsOrnament
    }
  }
}
</script>

<style>
</style>
