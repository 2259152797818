<template>
  <div
    id="app"
    :class="appElementClasses"
  >
    <div
      v-if="pageDataLoaded !== true"
      class="container"
    >
      <page-loading v-if="pageDataLoaded === null" />
      <page-load-error v-if="pageDataLoaded === false" />
    </div>
    <div v-show="pageDataLoaded === true">
      <app-alert />
      <app-header />
      <main
        role="main"
        class="pb-3"
      >
        <router-view />
      </main>
      <app-footer />
    </div>
  </div>
</template>

<script>
import AppAlert from './components/Shared/AppAlert'
import AppHeader from './components/Shared/Header/Header'
import AppFooter from './components/Shared/Footer'
import PageLoading from './components/Shared/PageLoading'
import PageLoadError from './components/Shared/PageLoadError'
import { getOrchardCoreContentModule, getOrchardCoreSubscriberContent } from './lib/api'
import { mutationTypes } from './store/modules/app'

export default {
  name: 'App',
  components: {
    AppAlert,
    AppHeader,
    AppFooter,
    PageLoading,
    PageLoadError
  },
  computed: {
    pageDataLoaded () {
      return this.$store.state.app.pageDataLoaded
    },
    appElementClasses () {
      return {
        'bg-white': this.pageDataLoaded === true
      }
    }
  },
  watch: {
    pageDataLoaded (v, ov) {
      const loadingClass = 'loading'
      const elHtml = document.getElementsByTagName('html')[0]
      const elBody = document.getElementsByTagName('body')[0]

      if (v === null) {
        if (!elHtml.classList.contains(loadingClass)) {
          elHtml.classList.add(loadingClass)
        }

        if (!elBody.classList.contains(loadingClass)) {
          elBody.classList.add(loadingClass)
        }
      } else {
        if (elHtml.classList.contains(loadingClass)) {
          elHtml.classList.remove(loadingClass)
        }

        if (elBody.classList.contains(loadingClass)) {
          elBody.classList.remove(loadingClass)
        }
      }
    }
  },
  async mounted () {
    const sharedContentResponse = await getOrchardCoreContentModule('shared')
    this.$oc.setContent(sharedContentResponse.data)

    const defaultSubcriberContentResponse = await getOrchardCoreSubscriberContent('default-subscriber')
    this.$store.commit(mutationTypes.APP__SET_DEFAULT_SUBSCRIBER_CONTENT, defaultSubcriberContentResponse.data)
  }
}
</script>

<style lang="scss" scoped>
</style>
