import axios from 'axios'

const ax = axios.create({
  baseURL: '/api'
})

export async function getOrchardCoreContentModule (contentModuleName) {
  return await ax.get(`/orchardcore/content-module/${contentModuleName}`)
}

export async function getOrchardCoreFaqs () {
  return await ax.get('/orchardcore/frequently-asked-questions')
}

export async function getOrchardCoreTestimonials () {
  return await ax.get('/orchardcore/testimonials')
}

export async function getOrcharcCoreFeaturedBys () {
  return await ax.get('/orchardcore/featured-bys')
}

export async function getOrchardCorePromotionBanners () {
  return await ax.get('/orchardcore/promotion-banners')
}

export async function getOrchardCoreSubscriberContent (subscriberContentId) {
  return await ax.get(`/orchardcore/subscriber-content/${subscriberContentId}`)
}

export async function getProcedureCategoryGroups () {
  return await ax.get('/data/procedure-category-groups')
}

export async function getProcedurePricingDetails (procedureId, zipCode, subscriberId) {
  if (subscriberId) {
    return await ax.get(`/data/procedure-pricing/${procedureId}/${zipCode}/${subscriberId}`)
  } else {
    return await ax.get(`/data/procedure-pricing/${procedureId}/${zipCode}`)
  }
}

export async function getProcedureCategory (categoryId) {
  return await ax.get(`/data/procedure-category/${categoryId}`)
}

export async function getProcedureSearch (query) {
  return await ax.get(`/data/procedure-search?query=${query}`)
}

export async function getLocationSearch (query) {
  return await ax.get(`/data/location-search?query=${query}`)
}

export async function postScheduleAppointmentRequest (scheduleAppointmentRequest) {
  return await ax.post('/data/create-appointment-request', scheduleAppointmentRequest)
}

export async function getSubscriberUser (subscriberUserId) {
  return await ax.get(`/data/subscriber-user/${subscriberUserId}`)
}

export async function getSubscriberFacilityExclusions (subscriberId) {
  return await ax.get(`/data/subscriber-facility-exclusions/${subscriberId}`)
}
