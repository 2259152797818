<template>
  <h1
    class="brand-logo"
    :style="brandLogoStyles"
  >
    Benefit<span class="brand-logo__second">Buddy</span>
  </h1>
</template>

<script>
export default {
  name: 'BrandLogo',
  props: {
    fontSize: { type: String, default: null, required: false }
  },
  computed: {
    brandLogoStyles () {
      const styles = {}

      if (this.fontSize !== null) {
        styles.fontSize = this.fontSize
      }

      return styles
    }
  }
}
</script>

<style lang="scss" scoped>
.brand-logo {
    font-size: 20px;
    font-weight: 600;
    color: $primary;
    margin: 0;
    padding: 0;

    &__second {
        color: $brand-green-2;
    }
}
</style>
