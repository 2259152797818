import OrchardcoreMixin from './mixins/OrchardCore'
import camelCase from 'camelcase'
import { find } from 'lodash'

const optionDefaults = {
  orchardCoreUrlBase: 'https://localhost:5003'
}

const OrchardCore = {
  install (Vue, opts) {
    const options = { ...optionDefaults, ...opts }

    const root = new Vue({
      data () {
        return {
          content: {},
          orchardCoreUrlBase: options.orchardCoreUrlBase,
          defaultCulture: 'en',
          selectedCulture: 'en'
        }
      },
      methods: {
        setContent (data) {
          data.textUnformattedItems.contentItems.forEach(contentItem => {
            this.content[camelCase(contentItem.alias)] = contentItem
          })

          data.textMarkdownItems.contentItems.forEach(contentItem => {
            this.content[camelCase(contentItem.alias)] = contentItem
          })

          data.imageItems.contentItems.forEach(contentItem => {
            this.content[camelCase(contentItem.alias)] = contentItem
          })
        },
        setSelectedCulture (culture) {
          this.selectedCulture = culture
        },
        getSelectedLocalization (item) {
          if (item.localizations === undefined) {
            throw Error('No localizations are present on this item')
          }

          const selectedLocalizationExact = find(item.localizations, ['localization.culture', this.selectedCulture])

          if (selectedLocalizationExact !== undefined && selectedLocalizationExact != null) {
            return selectedLocalizationExact
          }

          // if the exact culture is not found, try to find a fuzzy match by culture prefix (e.g. en-US -> en)
          const selectedLocalizationFuzzy = find(item.localizations, (localization) => {
            return localization.localization.culture.startsWith(this.selectedCulture)
          })

          if (selectedLocalizationFuzzy !== undefined && selectedLocalizationFuzzy != null) {
            return selectedLocalizationFuzzy
          }

          const defaultLocalization = find(item.localizations, ['localization.culture', this.defaultCulture])

          if (defaultLocalization !== undefined && defaultLocalization !== null) {
            return defaultLocalization
          } else {
            throw new Error('No valid localization found for item.')
          }
        },
        buildMediaUrl (urlSuffix) {
          return `${this.orchardCoreUrlBase}${urlSuffix}`
        }
      }
    })

    Vue.prototype.$oc = root

    Vue.mixin(
      OrchardcoreMixin
    )
  }
}

export default OrchardCore
