import OcContent from '../components/OrchardcoreContent'
import OcImage from '../components/OrchardcoreImage'
import OcText from '../components/OrchardcoreTextUnformatted'
import OcTextMd from '../components/OrchardcoreTextMarkdown'

export default {
  components: {
    OcContent,
    OcImage,
    OcText,
    OcTextMd
  }
}
