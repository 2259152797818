import Vue from 'vue'
import App from './App.vue'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import VueRouter from 'vue-router'
import routes from './router/routes'
import { MediaQueries, CommonBands } from 'vue-media-queries'
import { mutationTypes, actionTypes } from './store/modules/app'

import { getSubscriberUser } from './lib/api'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faBars,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faExclamationCircle,
  faHeadset,
  faInfoCircle,
  faMapMarker,
  faMapMarkerAlt,
  faMedkit,
  faPhoneAlt,
  faSearch,
  faSlidersV,
  faSpinner,
  faStar,
  faThumbsUp,
  faTimes,
  faUser
} from '@fortawesome/pro-solid-svg-icons'

import {
  faLongArrowLeft,
  faLongArrowRight
} from '@fortawesome/pro-light-svg-icons'

import {
  faCircle as farCircle // alias required because faCircle exists above already
} from '@fortawesome/pro-regular-svg-icons'

import VuePaginate from 'vue-paginate'
import VueScrollTo from 'vue-scrollto'
import OrchardCore from '../../shared/plugins/OrchardCore/OrchardCore'
import VueMeta from 'vue-meta'

import '../../../scss/app.scss'
import 'vue-transitions-css'

library.add([
  faBars,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faExclamationCircle,
  faHeadset,
  faInfoCircle,
  faLongArrowLeft,
  faLongArrowRight,
  faMapMarker,
  faMapMarkerAlt,
  faMedkit,
  faPhoneAlt,
  farCircle,
  faSearch,
  faSlidersV,
  faSpinner,
  faStar,
  faThumbsUp,
  faTimes,
  faUser
])

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(BootstrapVue)
Vue.use(VueRouter)
Vue.use(VuePaginate)
Vue.use(VueScrollTo)
Vue.use(OrchardCore, {
  orchardCoreUrlBase: process.env.VUE_APP_ORCHARD_CORE_BASE_URL,
  selectedCulture: 'en'
})
Vue.use(VueMeta)

const mediaQueries = new MediaQueries({
  bands: CommonBands.Bootstrap4
})

Vue.use(mediaQueries)

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  store.commit(mutationTypes.APP__SET_PAGE_DATA_LOADED, null)

  const signedInUserId = store.state.app.signedInUserId

  if (signedInUserId !== null && store.state.app.signedInUser === null) {
    const subscriberUserResponse = await getSubscriberUser(signedInUserId)
    store.commit(mutationTypes.APP__SET_SIGNED_IN_USER, subscriberUserResponse.data)

    if (subscriberUserResponse.data.subscriber !== null && subscriberUserResponse.data.subscriber.contentItemId !== null) {
      store.dispatch(actionTypes.APP__GET_SUBSCRIBER_CONTENT, subscriberUserResponse.data.subscriber.contentItemId)
    }
  }

  next()
})

if (!global.Intl) {
  global.Intl = require('intl')
}

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App),
  router,
  mediaQueries
}).$mount('#app')
