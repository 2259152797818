<template>
  <div
    class="pb-2 mb-4 faq-item"
    :data-orchardcore-contentitem="faq.contentItemId"
  >
    <div
      class="faq-item__header"
      @click="$emit('selected')"
    >
      <div class="row mt-3 align-items-center">
        <div class="col-9 col-md-11">
          <div class="faq-item__question py-2">
            {{ faq.localization.localizations[0].question }}
          </div>
        </div>
        <div class="col-3 col-md-1 text-right">
          <button class="btn btn-link faq-item__toggle-btn">
            <font-awesome-icon
              v-if="isSelected"
              icon="chevron-up"
            />
            <font-awesome-icon
              v-if="!isSelected"
              icon="chevron-down"
            />
          </button>
        </div>
      </div>
    </div>
    <b-collapse
      id="faq-collapse-1"
      :visible="isSelected"
    >
      <div class="row mb-2">
        <div class="col-11">
          <vue-markdown :source="faq.localization.localizations[0].answer.markdown" />
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'

export default {
  name: 'FaqItem',
  components: {
    VueMarkdown
  },
  props: {
    faq: { type: Object, default: null, required: true },
    isSelected: { type: Boolean, default: false, required: true }
  }
}
</script>

<style lang="scss" scoped>
.faq-item {
  border-bottom: 1px solid #ededed;

  &__question {
    color: $dark-blue-1;
    cursor: pointer;
    font-size: 1.1rem;
    line-height: 1.8rem;
  }

  &__toggle-btn {
    color: $primary;
    font-size: 0.8rem;
    padding-top: 0;
    padding-bottom: 0;
  }

  &__cta {
    border-radius: 15px;
    font-weight: bold;
    padding: 1.2rem 3rem;
  }

  @include media-breakpoint-up(md) {
    &__question {
      font-size: 1.4rem;
    }
  }
}
</style>
