import { getOrchardCoreSubscriberContent, getProcedureCategoryGroups, getSubscriberUser } from '../../lib/api'
import { v4 as uuidv4 } from 'uuid'

// prefix all getters, mutations and actions with APP__/app_ to "namespace" them

export const mutationTypes = {
  APP__SET_ALERT: 'app_SetAlert',
  APP__CLEAR_ALERT: 'app_ClearAlert',
  APP__SET_PAGE_DATA_LOADED: 'app_SetPageDataLoaded',
  APP__SET_SELECTED_PROCEDURE: 'app_SetselectedProcedure',
  APP__SET_SELECTED_LOCATION: 'app_SetSelectedLocation',
  APP__SET_PROCEDURE_CATEGORY_GROUPS: 'app_SetProcedureCategoryGroups',
  APP__SET_SIGNED_IN_USER_ID: 'app_SetSignedInUserId',
  APP__SET_SIGNED_IN_USER: 'app_SetSignedInUser',
  APP__SET_DEFAULT_SUBSCRIBER_CONTENT: 'app_SetDefaultSubscriberContent',
  APP__SET_SIGNED_IN_SUBSCRIBER_CONTENT: 'app_SetSignedInSubscriberContent'
}

export const getterTypes = {
  APP__GET_SELECTED_PROCEDURE_TRAIL: 'app_GetSelectedProcedureTrail'
}

export const actionTypes = {
  APP__GET_APP_DATA: 'app_GetAppData',
  APP__GET_SUBSCRIBER_CONTENT: 'app_GetSubscriberContent',
  APP__SIGN_IN_USER: 'app_SignInUser',
  APP__SIGN_OUT_USER: 'app_SignOutUser'
}

const localStorageVariables = {
  SIGNED_IN_USER_ID: 'signedInUserId'
}

export default {
  state: {
    alertId: null,
    alertMsg: null,
    alertVariant: null,
    benefitBuddyPhoneNumber: '850-898-1410',
    defaultSubscriberContent: null,
    pageDataLoaded: null,
    procedureCategoryGroups: null,
    selectedLocation: null,
    selectedProcedure: null,
    signedInUserId: window.localStorage.getItem(localStorageVariables.SIGNED_IN_USER_ID),
    signedInUser: null,
    signedInUserSubscriberContent: null
  },
  mutations: {
    [mutationTypes.APP__SET_ALERT]: (state, value) => { // expects value to be object defined as { msg: ..., variant: ... }
      state.alertMsg = value.msg
      state.alertVariant = value.variant ?? 'success'
      state.alertId = uuidv4()
    },
    [mutationTypes.APP__CLEAR_ALERT]: (state) => {
      state.alertId = null
      state.alertMsg = null
      state.alertVariant = null
    },
    [mutationTypes.APP__SET_PAGE_DATA_LOADED]: (state, value) => {
      state.pageDataLoaded = value
    },
    [mutationTypes.APP__SET_SELECTED_PROCEDURE]: (state, value) => {
      state.selectedProcedure = value
    },
    [mutationTypes.APP__SET_SELECTED_LOCATION]: (state, value) => {
      state.selectedLocation = value
    },
    [mutationTypes.APP__SET_PROCEDURE_CATEGORY_GROUPS]: (state, value) => {
      state.procedureCategoryGroups = value
    },
    [mutationTypes.APP__SET_SIGNED_IN_USER_ID]: (state, value) => {
      state.signedInUserId = value
    },
    [mutationTypes.APP__SET_SIGNED_IN_USER]: (state, value) => {
      state.signedInUser = value
    },
    [mutationTypes.APP__SET_DEFAULT_SUBSCRIBER_CONTENT]: (state, value) => {
      state.defaultSubscriberContent = value
    },
    [mutationTypes.APP__SET_SIGNED_IN_SUBSCRIBER_CONTENT]: (state, value) => {
      state.signedInUserSubscriberContent = value
    }
  },
  getters: {
    [getterTypes.APP__GET_SELECTED_PROCEDURE_TRAIL]: state => {
      if (!state.selectedProcedure) {
        return null
      }

      const procedureHierarchyFlattenedMap = state.procedureCategoryGroups.map(pcg => {
        return pcg.categories.map(c => {
          return c.procedures.map(p => {
            return { categoryId: pcg.categoryGroupId, procedureTypeId: c.categoryId, procedureId: p.procedureId }
          })
        })
      }).flat(3)

      const result = procedureHierarchyFlattenedMap.find(phm => { return phm.procedureId === state.selectedProcedure.procedureId })

      return result
    }
  },
  actions: {
    async [actionTypes.APP__GET_APP_DATA] (context) {
      const response = await getProcedureCategoryGroups()

      if (response.status === 200) {
        context.commit(mutationTypes.APP__SET_PROCEDURE_CATEGORY_GROUPS, response.data)
      }
    },
    async [actionTypes.APP__SIGN_IN_USER] ({ commit, dispatch }, userId) {
      commit(mutationTypes.APP__SET_PAGE_DATA_LOADED, null)

      window.localStorage.setItem(localStorageVariables.SIGNED_IN_USER_ID, userId)

      commit(mutationTypes.APP__SET_SIGNED_IN_USER_ID, userId)

      const subscriberUserResponse = await getSubscriberUser(userId)
      commit(mutationTypes.APP__SET_SIGNED_IN_USER, subscriberUserResponse.data)

      dispatch(actionTypes.APP__GET_SUBSCRIBER_CONTENT, 'subscriber-a')

      commit(mutationTypes.APP__SET_ALERT, { msg: "You've successfully signed in.", variant: 'success' })
      commit(mutationTypes.APP__SET_PAGE_DATA_LOADED, true)
    },
    async [actionTypes.APP__SIGN_OUT_USER] ({ commit }) {
      commit(mutationTypes.APP__SET_PAGE_DATA_LOADED, null)

      commit(mutationTypes.APP__SET_SIGNED_IN_USER_ID, null)
      commit(mutationTypes.APP__SET_SIGNED_IN_USER, null)
      commit(mutationTypes.APP__SET_SIGNED_IN_SUBSCRIBER_CONTENT, null)

      window.localStorage.removeItem(localStorageVariables.SIGNED_IN_USER_ID)

      commit(mutationTypes.APP__SET_ALERT, { msg: "You've successfully signed out.", variant: 'success' })
      commit(mutationTypes.APP__SET_PAGE_DATA_LOADED, true)
    },
    async [actionTypes.APP__GET_SUBSCRIBER_CONTENT] ({ commit }, subscriberContentId) {
      const signedInUserSubscriberContentResponse = await getOrchardCoreSubscriberContent(subscriberContentId)
      commit(mutationTypes.APP__SET_SIGNED_IN_SUBSCRIBER_CONTENT, signedInUserSubscriberContentResponse.data)
    }
  }
}
