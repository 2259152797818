export default [
  {
    id: '67fa5111-2a8a-4ca3-bc52-3b29687cc0b5',
    username: 'demo1@benefitbuddy.com',
    password: 'Testing456!'
  },
  {
    id: 'f62f078b-a63a-4844-acd6-e10b4f662623',
    username: 'demo2@benefitbuddy.com',
    password: 'Testing789!'
  }
]
