<template>
  <div class="why-section">
    <div class="container-fluid container-lg py-4">
      <div class="row">
        <div class="col-10 offset-1 col-lg-8 offset-lg-2">
          <div class="row">
            <div class="col-12 col-md-4 col-lg-3 offset-lg-1">
              <h2 class="why-section__title mb-4">
                <oc-content :item="$oc.content.homePageWhyTitle" />
                <dots-ornament />
              </h2>
            </div>
            <div class="col-12 col-md-8">
              <div class="why-section__lede mb-2 mb-lg-4">
                <oc-content :item="$oc.content.homePageWhyLede" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- TODO CMS item can be removed
    <oc-content :item="$oc.content.homePageWhyImage" />
  -->
</template>

<script>
import DotsOrnament from '../Shared/DotsOrnament'

export default {
  name: 'WhySection',
  components: {
    DotsOrnament
  }
}
</script>

<style lang="scss" scoped>
.why-section {
  margin-bottom: 4rem;

  &__title {
    font-size: 1.9rem;
    line-height: 2.2rem;
    font-weight: 600;
    text-align: center;
  }

  &__lede {
    font-size: 1.3rem;
    line-height: 2rem;
    text-align: center;
  }

  @include media-breakpoint-up(md) {
    &__title {
      text-align: left;
    }

    &__lede {
      text-align: left;
    }
  }
}
</style>
