<template>
  <div class="container featured-by-section">
    <h3 class="font-weight-normal mb-3 text-center featured-by-section__header">
      <oc-content :item="$oc.content.homePageFeaturedByTitle" />
    </h3>
    <div class="row">
      <div class="col-12 col-md-10 offset-md-1 featured-by-section__items">
        <div class="row align-items-center">
          <div
            v-for="(featuredBy, fbIndex) in featuredBysSorted"
            :key="fbIndex"
            class="col-8 offset-2 col-md offset-md-0 text-center my-4 my-md-0"
          >
            <featured-by :featured-by="featuredBy" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FeaturedBy from './FeaturedBy'
import { orderBy } from 'lodash'

export default {
  name: 'FeaturedBySection',
  components: {
    FeaturedBy
  },
  props: {
    featuredBys: { type: Array, default: function () { return [] }, required: true }
  },
  computed: {
    featuredBysSorted () {
      return orderBy(this.featuredBys, ['sortOrder', 'asc'])
    }
  }
}
</script>

<style lang="scss" scoped>
.featured-by-section {
  margin-bottom: 60px;

  &__header {
    color: $white;
  }

  &__items {
    background-color: $white;
    box-shadow: 0 5px 15px -2px #d3d3ff;
    padding: 30px;
  }

  @include media-breakpoint-up(md) {
    &__items {
      border-radius: 15px;
    }
  }
}
</style>
