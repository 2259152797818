<template>
  <div
    id="jumbotron"
    class="jumbotron text-center"
  >
    <div class="container-fluid container-lg jumbotron__container">
      <h1 class="mb-4 jumbotron__header">
        <oc-content :item="$oc.content.homePageJumbotronTitle" />
      </h1>
      <p class="lead mb-4 jumbotron__lead">
        <oc-content :item="$oc.content.homePageJumbotronSubtitle" />
      </p>
      <div class="row no-gutters">
        <div class="col-12 col-md-10 offset-md-1 col-lg-12 offset-lg-0">
          <search-procedure-location :display-mode="searchDisplayModes.featured" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchProcedureLocation, { displayModes as searchDisplayModes } from '../Shared/Search/SearchProcedureLocation'

export default {
  name: 'JumbotronSection',
  components: {
    SearchProcedureLocation
  },
  data () {
    return {
      searchDisplayModes
    }
  }
}
</script>

<style lang="scss" scoped>
.jumbotron {
  background-color: transparent;
  padding: 0;
  margin-bottom: 0;
  margin-top: -20px;

  &__container {
    padding: 2rem 1rem;
  }

  &__header {
    color: $white;
    font-size: 2rem;
    font-weight: 600;
  }

  &__lead {
    color: $white;
  }

  &__cta {
    border-radius: 15px;
    font-weight: bold;
    padding: 1.2rem 3rem;
  }

  @include media-breakpoint-up(md) {
    font-size: 3.5rem;

    &__container {
      padding: 8rem 0 6rem 0;
    }

    &__header {
      font-size: 2.6rem;
    }
  }

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }
}
</style>
